// @flow

import { Component } from 'react';
import { chatHiddenCls } from 'shared_styles/app.less';

/**
 * UIInit
 * Component to execite UI actions based on the routeConfig page ui settings
 *
 */
export class UIInit extends Component<*, *> {
  /**
   * constructor
   * @param {Object} props
   */
  constructor(props: Object) {
    super(props);
    this.init();
  }

  /**
   * init
   * Initialize different UI methods
   */
  init() {
    this.toggleChat();
  }

  /**
   * toggleChat
   * Hide/Show chat depending on the routeConfig ui setting
   */
  toggleChat() {
    const {
      routeConfig: { ui: uiConfig },
    } = this.props;
    const { classList } = document.body || {};

    if (classList) {
      uiConfig && uiConfig.hideChat
        ? classList.add(chatHiddenCls)
        : classList.remove(chatHiddenCls);
    }
  }

  /**
   * render
   */
  render() {
    return this.props.children || null;
  }
}
