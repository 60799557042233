// @flow

import { v4 } from 'uuid';
import queryString from 'query-string';
import get from 'lodash/get';
import find from 'lodash/find';
import React, { Component } from 'react';
import { withTranslatedRouter } from 'shared_data/providers/url/withTranslatedRouter';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import {
  SliderPagination,
  InfiniteScrollPagination,
  ScrollToTopButton,
  scrollToElement,
} from '@riseart/common';
import { FilterService } from '@riseart/filter-service';
import { GridPaginationWrapper } from '@riseart/grid';
import { components as COMPONENTS_CONFIG } from 'Config';
import { art as ART_ENUM, pagination as PAGINATION_ENUM, meta as META_ENUM } from 'Enum';
import { Query } from 'shared_services/apollo/Query';
import { LDTYPES, generateLDJSON } from 'shared_models/seo/ldJSON';
import { MetaProvider } from 'shared_data/providers/meta/Meta';
import { UrlAssembler } from 'shared_services/riseart/utils/UrlAssembler';
import { getFilterDomainConfig } from 'shared_models/configs/filters/domains';
import { selectAuthShippingCountryCode } from 'shared_services/redux/selectors/auth';
import { selectStoreCode } from 'shared_services/redux/selectors/storeCode';
import { selectVisitorId } from 'shared_services/redux/selectors/visitor';
import { withFilterState } from 'shared_hocs/filters/withFilterState';
import { IsomorphicRipple } from 'shared_components/common/preloader/IsomorphicRipple';
import { ArtGridFluid } from 'shared_components/arts/containers/grid/Fluid';
import { Pagination } from 'shared_hocs/pagination/Pagination';
import { LocationManager } from 'shared_services/riseart/url/Location';
import { generateSrcForCDN } from 'shared_components/common/artdirection/picture/Picture';
import {
  DEFAULT_STORE_STATE as FILTER_DEFAULT_STATE,
  STORE_KEYS as FILTER_ART_STORE_KEYS,
} from 'shared_services/redux/states/filter';
import { DataProviderPreloaderPage } from 'shared_components/data/providers/preloaders/Page';
import { gtmTabOpen, gtmTabClose } from 'shared_data/providers/google/tagmanager/Actions';
import { areDifferent, convertPayloadToQueryVars } from 'shared_services/riseart/utils/Utils';
import { MESSAGE_TYPES, RiseartLogger } from 'shared_services/riseart/Logger';

// Constants
const DEFAULT_ITEMS_NUMBER_PER_FETCH = 30;
const HOC_DISPLAY_NAME = 'HOCArtsFilterGrid';
const IMAGE_SIZE_SQUARE = '300x300';
const IMAGE_SIZE_FLUID = '300xAUTO';

/**
 * HOC
 *
 * @param ArtSection
 * @param {Object} options
 * @constructor
 */
function HOC(ArtSection: React$Component<*, *>, options: Object = {}) {
  // Set default options
  options.sortOptions = options.sortOptions || ART_ENUM.rank;

  return class extends Component<Object, *> {
    static displayName = HOC_DISPLAY_NAME;

    currentFilters: ?Object = null;

    itemsList: Array<?Object> = [];

    filterService: Object;

    preloaderUniqueId: string;

    availableLayoutOptions: Object;

    availableSortOptions: Object;

    paginationState: Object;

    scrollToTopOfGrid: Function;

    /**
     * Constructor
     *
     * @param props
     */
    constructor(props) {
      super(props);

      this.gridRef = React.createRef();
      this.bindMethods();
      this.availableLayoutOptions = this.optionsFactory(ART_ENUM.list, 'options.layout');
      this.availableSortOptions = this.optionsFactory(options.sortOptions, 'options.sort.art');
      this.paginationState = this.initialPaginationState();

      /*
        paginated data is not loaded server side, so in this case
        the query string 'page' parameter is used as source of truth for the pagination
        and it calculates the initial pagination data based on it,
        although it might be different than the actual one
      */
      if (props.isSSR) {
        let pageFromUrlParam = parseInt(
          queryString.parse(this.props.location.search).page || 1,
          10,
        );

        pageFromUrlParam = pageFromUrlParam >= 1 ? pageFromUrlParam : 1;

        // assumed that totalPages is always the current ?page={number} in url + 1
        const totalPages = pageFromUrlParam + 1;
        this.paginationState = {
          ...this.paginationState,
          current: pageFromUrlParam,
          totalPages,
          total: totalPages * (props.itemsPerPage || DEFAULT_ITEMS_NUMBER_PER_FETCH),
        };
      }

      this.filterService = new FilterService(
        getFilterDomainConfig(this.props.domain, this.props.match.params),
        null,
        FILTER_DEFAULT_STATE[this.props.domain],
      );

      this.preloaderUniqueId = v4();
    }

    /**
     * initialPaginationState
     */
    initialPaginationState() {
      return {
        current: 1,
        totalPages: 0,
        total: 0,
        pageSize: this.props.itemsPerPage || DEFAULT_ITEMS_NUMBER_PER_FETCH,
        isLoading: false,
      };
    }

    /**
     * getImageSizeByLayoutType
     *
     * @param layout
     * @returns {*}
     */
    getImageSizeByLayoutType(layout = ART_ENUM.list.LAYOUT_SQUARE) {
      switch (layout) {
        case ART_ENUM.list.LAYOUT_SQUARE:
          return IMAGE_SIZE_SQUARE;
        case ART_ENUM.list.LAYOUT_FLUID:
        default:
          return IMAGE_SIZE_FLUID;
      }
    }

    /**
     * getSortOptions
     *
     * @returns {*}
     */
    getSortOptions(currentSort) {
      const parsedQueryString = queryString.parse(this.props.location.search);

      if (!currentSort) {
        currentSort = FILTER_DEFAULT_STATE[this.props.domain][FILTER_ART_STORE_KEYS.SORT];
      }
      Object.keys(this.availableSortOptions).forEach((key) => {
        this.availableSortOptions[key].selected =
          this.availableSortOptions[key].value === currentSort;
      });

      return {
        label: this.props.intl.formatMessage({ id: 'components.art.sort' }),
        options:
          // filter out relevance when q is provided or not
          parsedQueryString.q === undefined
            ? this.availableSortOptions.filter(
                ({ value }) => value !== ART_ENUM.rank.RANKING_RELEVANCE,
              )
            : this.availableSortOptions,
      };
    }

    /**
     * getLayoutOptions
     *
     * @param currentLayout
     * @returns {*}
     */
    getLayoutOptions(currentLayout) {
      if (!currentLayout) {
        currentLayout = FILTER_DEFAULT_STATE[this.props.domain][FILTER_ART_STORE_KEYS.LAYOUT];
      }
      Object.keys(this.availableLayoutOptions).forEach((key) => {
        this.availableLayoutOptions[key].selected =
          this.availableLayoutOptions[key].value === currentLayout;
      });
      return this.availableLayoutOptions;
    }

    /**
     * optionsFactory
     *
     * @param data
     * @param dictionaryBasePath
     * @returns {Array}
     */
    optionsFactory(optionsData, dictionaryBasePath) {
      if (!optionsData || !dictionaryBasePath) {
        RiseartLogger.message({
          message:
            'In order to use the options factory you need to define an array of options and the dictionary path',
          level: MESSAGE_TYPES.ERROR,
          data: {
            scope: 'HOCArtsGrid',
            optionsData,
            dictionaryBasePath,
          },
        });
      }

      return Object.keys(optionsData).map((key) => ({
        label: this.props.intl.formatMessage({ id: `${dictionaryBasePath}.${optionsData[key]}` }),
        value: optionsData[key],
        url: null,
        selected: false,
      }));
    }

    /**
     * sortHandler
     *
     * @param selectedSorted
     */
    sortHandler: Function;

    sortHandler(selectedSorted) {
      const parsedQueryString = queryString.parse(this.props.location.search);
      const filterDomainConfig = this.filterService.getConfig();

      this.props.history.push(
        this.filterService.assemble(
          this.props.filterState.filters,
          {
            layout: this.props.filterState.layout,
            sort: selectedSorted,
            q: this.props.filterState.q,
            v: this.props.filterState.v,
          },
          {
            ...FILTER_DEFAULT_STATE[this.props.domain],
            // if textSearch (q) is provided, then default value of the filter
            // has to be different depending on the domain config
            ...(parsedQueryString.q !== undefined && filterDomainConfig.textSearchDefaultSort
              ? { sort: filterDomainConfig.textSearchDefaultSort }
              : null),
          },
        ),
      );
    }

    /**
     * layoutHandler
     *
     * @param selectedLayout
     */
    layoutHandler: Function;

    layoutHandler(selectedLayout) {
      this.props.history.push(
        this.filterService.assemble(this.props.filterState.filters, {
          page: this.props.filterState.page,
          layout: selectedLayout,
          sort: this.props.filterState.sort,
          q: this.props.filterState.q,
          v: this.props.filterState.v,
        }),
      );
    }

    /**
     * toggleArtTabHandler
     *
     * @param {string} url
     * @param {boolean} isOpened
     * @returns {void}
     */
    toggleArtTabHandler: Function;

    toggleArtTabHandler(data: Object, isOpened: boolean = false): void {
      isOpened ? this.props.actionGtmTabOpen(data) : this.props.actionGtmTabClose(data);
    }

    /**
     * beforeInfinitePageChange
     *
     * @returns {void}
     */
    beforeInfinitePageChange: Function;

    beforeInfinitePageChange(): void {
      this.paginationState.isLoading = true;
    }

    /**
     * updateItemsList
     *
     * @param {Object} data
     * @returns {void}
     */
    updateItemsList: Function;

    updateItemsList(responseData: Object = null): void {
      if (!responseData) {
        this.paginationState.isLoading = false;
        return;
      }

      const { pagination, items } = responseData;

      // Append to list of items for infinte scroll, otherwise create new list with items for slider pagination
      if (
        this.props.paginationType === PAGINATION_ENUM.type.INFINITE &&
        this.paginationState.isLoading
      ) {
        this.itemsList = [...this.itemsList, ...items];
      } else {
        this.itemsList = [...items];
      }

      // Update paginationState with pagination data from query after loading new items in this.itemsList
      this.paginationState = {
        ...this.paginationState,
        current: pagination.currentPage,
        totalPages: pagination.totalPages,
        total: pagination.totalItems,
        pageSize: pagination.itemsPerPage,
        isLoading: false,
      };
    }

    /**
     * resetGrid
     */
    resetGrid() {
      this.itemsList = [];
      this.paginationState = this.initialPaginationState();
    }

    /**
     * bindMethods
     */
    bindMethods() {
      this.beforeInfinitePageChange = this.beforeInfinitePageChange.bind(this);
      this.sortHandler = this.sortHandler.bind(this);
      this.layoutHandler = this.layoutHandler.bind(this);
      this.toggleArtTabHandler = this.toggleArtTabHandler.bind(this);
      this.updateItemsList = this.updateItemsList.bind(this);
      this.scrollToTopOfGrid = scrollToElement.bind(this, this.gridRef);
    }

    /**
     * render
     */
    render() {
      const {
        fetchQuery,
        isSsrQuery = false,
        fetchPolicy,
        filterState,
        storeCode,
        additionalActionControls = null,
        onLoadData,
        itemsPerPage,
        sendVisitorId = true,
        tracking,
        includeLDJSON,
      } = this.props;
      const queryDataName = get(fetchQuery, 'definitions[0].name.value');
      const artsFilterListPayload = {
        items: itemsPerPage || DEFAULT_ITEMS_NUMBER_PER_FETCH,
        page: filterState.page,
        sort: filterState.sort,
        ...filterState.filters,
      };

      // Compare if filters or sort changes and resets the grid
      if (
        this.currentFilters &&
        filterState &&
        (areDifferent(this.currentFilters.filters, filterState.filters) ||
          (this.currentFilters && this.currentFilters.sort !== filterState.sort))
      ) {
        this.resetGrid();
      }

      // Card layout does not trigger the query or updates the items in grid.
      // It simply passes a property to each item image
      const wasLayoutOptionUpdated =
        this.currentFilters && filterState && this.currentFilters.layout !== filterState.layout;

      this.currentFilters = { ...filterState, storeCode };

      return (
        <Query
          query={fetchQuery}
          variables={{
            ...(this.props.additionalFilterQueryParams
              ? (typeof this.props.additionalFilterQueryParams === 'function' &&
                  this.props.additionalFilterQueryParams({
                    visitorId: this.props.visitorId,
                    shippingCountryCode: this.props.shippingCountryCode,
                    storeCode,
                    filters: filterState.filters,
                  })) ||
                this.props.additionalFilterQueryParams
              : null),
            ...(this.props.collectionId ? { collectionId: this.props.collectionId } : null),
            ...(this.props.visitorId && sendVisitorId ? { visitorId: this.props.visitorId } : null),
            inputArtFilter: convertPayloadToQueryVars(artsFilterListPayload, storeCode),
          }}
          {...(fetchPolicy ? { fetchPolicy } : null)}
          skip={!storeCode || this.props.skip}
          ssr={isSsrQuery}
          context={{
            customOptions: {
              errorSuppressFromResponse: true,
              // Suppress all errors from filterMetaCategories here (only executed in SSR queries)
              errorFilter: (errorList) =>
                errorList &&
                errorList.filter(
                  (error) => error.path && error.path.indexOf('filterMetaCategories') === -1,
                ),
            },
          }}
        >
          {({ loading, data, error }) => {
            const responseData = data ? (data && data.searchArt) || data[queryDataName] : null;

            if (typeof onLoadData === 'function' && !this.props.skip) {
              onLoadData(data);
            }

            // Update the items that will be shown before rendering the grid
            if (data && !loading && !error && !wasLayoutOptionUpdated) {
              this.updateItemsList(responseData);
            }

            const origin = LocationManager.get('origin');
            const totalItems = this.itemsList.length;
            let itemsCopy = [...this.itemsList];
            const { promotionalCards } = this.props;

            if (itemsCopy.length && promotionalCards && promotionalCards.length) {
              // Infinite pagination promotion cards. The results are accumualted from all pages
              if (this.props.paginationType === PAGINATION_ENUM.type.INFINITE) {
                const itemsCopyList = [...promotionalCards]
                  .sort((a, b) => {
                    if (a.listPosition < b.listPosition) {
                      return -1;
                    }

                    if (a.listPosition > b.listPosition) {
                      return 1;
                    }

                    return 0;
                  })
                  .reduce(
                    (accumulator, card) => {
                      if (card.listPosition <= totalItems) {
                        accumulator.items.splice(card.listPosition - 1, 0, {
                          id: `${card.code}-${card.listPosition}`,
                          isPromotion: true,
                          ...card,
                        });
                      } else if (
                        !accumulator.items[accumulator.items.length - 1].isPromotion &&
                        !accumulator.isLastIncluded
                      ) {
                        accumulator.isLastIncluded = true;
                        accumulator.items.push({
                          id: `${card.code}-${card.listPosition}`,
                          isPromotion: true,
                          ...card,
                        });
                      }

                      return accumulator;
                    },
                    { items: [...itemsCopy], isLastIncluded: false },
                  );

                itemsCopy = itemsCopyList.items;
              } else {
                // Slider pagination promotion cards. Results are paginated and the promotional cards position is calculated based on the page and its items
                const itemsFrom =
                  this.paginationState.pageSize * (this.paginationState.current - 1);
                const itemsTo =
                  this.paginationState.total > itemsFrom + this.paginationState.pageSize
                    ? itemsFrom + this.paginationState.pageSize
                    : this.paginationState.total;

                itemsCopy = promotionalCards.reduce(
                  (accumulator, card) => {
                    if (card.listPosition >= itemsFrom && card.listPosition < itemsTo) {
                      const setAtPosition = card.listPosition - itemsFrom;
                      accumulator.splice(
                        setAtPosition === 0 ? setAtPosition : setAtPosition - 1,
                        0,
                        {
                          id: `${card.code}-${card.listPosition}`,
                          isPromotion: true,
                          ...card,
                        },
                      );
                    }

                    return accumulator;
                  },
                  [...itemsCopy],
                );
              }
            }

            const nonPromotionalItems = itemsCopy.filter(({ isPromotion }: Object) => !isPromotion);
            let trackedItems = [...nonPromotionalItems];
            const FALLBACK_ART_SQUARE = find(COMPONENTS_CONFIG.art.fallbackImages, {
              type: ART_ENUM.image.type.TYPE_MAIN_SQUARE,
            });

            if (this.props.paginationType === PAGINATION_ENUM.type.INFINITE) {
              const from = (this.paginationState.current - 1) * this.paginationState.pageSize;
              const to = this.paginationState.current * this.paginationState.pageSize;
              trackedItems = trackedItems.slice(from, to);
            }

            // Render grid
            return (
              <React.Fragment>
                {typeof this.props.children === 'function'
                  ? this.props.children({ loading, data, error, responseData })
                  : null}
                <DataProviderPreloaderPage
                  uniqueId={this.preloaderUniqueId}
                  attach={loading || !storeCode}
                />
                <span ref={this.gridRef} />
                <ArtSection
                  hideSort={this.props.hideSort}
                  sortOptions={this.getSortOptions(filterState.sort)}
                  sortListener={this.sortHandler}
                  layoutOptions={this.getLayoutOptions(filterState.layout)}
                  layoutListener={this.layoutHandler}
                  layoutSwitch={this.props.layoutSwitch}
                  pagination={{ totalItems: this.paginationState.total }}
                  isLoading={!this.paginationState.isLoading && (loading || !storeCode)}
                >
                  {includeLDJSON && nonPromotionalItems && nonPromotionalItems.length ? (
                    <MetaProvider
                      cacheState={false}
                      meta={{
                        [META_ENUM.METATYPE.SCRIPT_LD_JSON_ARTWORKS_LIST]: generateLDJSON(
                          LDTYPES.CAROUSEL_ART,
                          (this.props.paginationType === PAGINATION_ENUM.type.INFINITE
                            ? nonPromotionalItems.slice(0, this.paginationState.pageSize)
                            : nonPromotionalItems
                          ).map((i: Object) => ({
                            ...i,
                            url: UrlAssembler.artDetail(i.id, i.slug, { origin }),
                            artistUrl: i.artistId
                              ? UrlAssembler.artistProfile(i.artistId, i.artistAlias, { origin })
                              : null,
                            image: generateSrcForCDN({
                              ...(find(i.images, {
                                type: ART_ENUM.image.type.TYPE_FLAT_SQUARE,
                              }) || FALLBACK_ART_SQUARE),
                              width: 1200,
                            }),
                          })),
                        ),
                      }}
                    />
                  ) : null}
                  <ArtGridFluid
                    loading={!this.paginationState.isLoading && (loading || !storeCode)}
                    items={itemsCopy}
                    trackedItems={trackedItems}
                    imageType={filterState.layout}
                    toggleArtTabHandler={this.toggleArtTabHandler}
                    wrapperPaddings={this.props.wrapperPaddings}
                    cardDisplayStyle={this.props.cardDisplayStyle}
                    showAddToCart={this.props.showAddToCart}
                    additionalActionControls={additionalActionControls}
                    showHoverContent={this.props.showHoverContent}
                    tracking={tracking}
                    noItems={this.props.noItems}
                    lazyloadAfter={this.props.lazyloadAfter}
                  />
                  {(this.props.paginationType === PAGINATION_ENUM.type.SLIDER &&
                    this.props.isSSR &&
                    this.paginationState.totalPages > 0) ||
                  (this.itemsList && this.itemsList.length) ? (
                    <GridPaginationWrapper>
                      <Pagination
                        paginationType={this.props.paginationType}
                        current={this.paginationState.current}
                        total={this.paginationState.total}
                        totalPages={this.paginationState.totalPages}
                        pageSize={this.paginationState.pageSize}
                        pageUrl={this.props.pageUrl}
                        beforeChange={
                          (this.props.paginationType === PAGINATION_ENUM.type.INFINITE &&
                            this.beforeInfinitePageChange) ||
                          this.scrollToTopOfGrid
                        }
                        isLoading={this.paginationState.isLoading}
                        offset={{ top: 0, left: 0, bottom: -1000, right: 0 }}
                        regenerateMetaOnHydration={!this.props.isSSR && !!responseData}
                      >
                        {(props) => {
                          return this.props.paginationType === PAGINATION_ENUM.type.SLIDER ? (
                            <SliderPagination {...props} />
                          ) : (
                            <InfiniteScrollPagination {...props} loader={IsomorphicRipple} />
                          );
                        }}
                      </Pagination>
                    </GridPaginationWrapper>
                  ) : null}
                  {/* has to be outside of GridPaginationWrapper because of the css position in ScrollToTopButton */}
                  {this.props.paginationType === PAGINATION_ENUM.type.INFINITE ? (
                    <ScrollToTopButton />
                  ) : null}
                </ArtSection>
              </React.Fragment>
            );
          }}
        </Query>
      );
    }
  };
}

/**
 * HOCArtsGrid
 *
 * @param ArtSection
 * @returns {*}
 * @constructor
 */
export function HOCArtsGrid(ArtSection: React$Component<*, *>, options: Object) {
  return withTranslatedRouter(
    injectIntl(
      withFilterState(
        options.domain,
        connect((state) => ({
          shippingCountryCode: selectAuthShippingCountryCode(state),
          visitorId: selectVisitorId(state),
          storeCode: selectStoreCode(state),
        }))(HOC(ArtSection, options)),
        undefined,
        { actionGtmTabOpen: gtmTabOpen, actionGtmTabClose: gtmTabClose },
      ),
    ),
  );
}
