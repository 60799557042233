// @flow

import React, { Component } from 'react';
import type { Node } from 'react';
import { EnquireSuccessSection } from 'shared_components/forms/enquire/sections/Success';

type Props = {
  onClose: Function,
  children: Function,
  message?: Node,
};

type State = {
  success: boolean,
};

/**
 * WithEnquireSuccess
 */
export class WithEnquireSuccess extends Component<Props, State> {
  /**
   * constructor
   *
   * @param {Props} props
   */
  constructor(props: Props) {
    super(props);

    this.state = { success: false };
    this.bindMethods();
  }

  /**
   * handleSubmit
   */
  handleSubmit: Function;

  handleSubmit() {
    this.setState({ success: true });
  }

  /**
   * bindMethods
   */
  bindMethods() {
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  /**
   * render
   */
  render() {
    const { children, onClose, message } = this.props;
    const { success } = this.state;

    if (success) {
      return <EnquireSuccessSection onClose={onClose} {...(message ? { message } : {})} />;
    }

    return children({ success, onSubmit: this.handleSubmit });
  }
}
