// @flow

import React from 'react';
import type { Node } from 'react';
import { FormattedMessage } from 'react-intl';

export class PaymentBase {
  static PAYMENT_STATUS: Object = {
    PROCESSING: 'processing',
    CANCELED: 'canceled',
    COMPLETE: 'complete',
    FAILED: 'failed',
    ERROR: 'error',
  };

  static ORDER_NEXT_STEPS: Object = {
    CONFIRM: 'confirm',
    CANCEL: 'cancel',
    CART: 'cart',
  };

  /**
   * getOrderNextAction
   *
   * @returns {Promise<Object>}
   */
  async getOrderNextAction(): Promise<Object> {
    return new Promise((resolve) =>
      resolve({
        nextAction: PaymentBase.ORDER_NEXT_STEPS.CONFIRM,
        triggerConfirmationEvent: false,
        redirect: false,
      }),
    );
  }

  /**
   * canUseSavedPayments
   *
   * @param {'buy' | 'rent'} cartType
   * @returns {boolean}
   */
  // eslint-disable-next-line
  static canUseSavedPayments(cartType: 'buy' | 'rent'): boolean {
    return false;
  }

  /**
   * getSupportedCards
   *
   * @returns {Object | null}
   */
  static getSupportedCards(): Object | null {
    return null;
  }

  /**
   * hasInternalValidation
   *
   * @returns {boolean}
   */
  static hasInternalValidation(): boolean {
    return false;
  }

  /**
   * renderBillingInfo
   *
   * @returns {Object | null}
   */
  static renderBillingInfo(): Object | null {
    return null;
  }

  /**
   * getPaymentProvider
   *
   * @returns {Object | null}
   */
  static getPaymentProvider(): Object | null {
    return null;
  }

  /**
   * getPaymentForm
   *
   * @returns {Object | null}
   */
  static getPaymentForm(): Object | null {
    return null;
  }

  /**
   * enhanceCardData
   *
   * @returns {Object | null}
   */
  static enhanceCardData(): Object | null {
    return null;
  }

  /**
   * getPaymentMethodName
   *
   * @returns {void}
   */
  static getPaymentMethodName(): void {}

  /**
   * isEnabled
   *
   * @returns {boolean}
   */
  static isEnabled(): boolean {
    return false;
  }

  /**
   * isReady
   *
   * @returns {boolean}
   */
  static isReady(): boolean {
    return true;
  }

  /**
   * delayReadOrder
   *
   * @returns {number}
   */
  static delayReadOrder(): number {
    return 0;
  }

  /**
   * getCheckoutButtonText
   *
   * @returns {Node}
   */
  getCheckoutButtonText(): Node {
    return (
      <FormattedMessage id="components.checkout.placeOrder">
        {(text: string) => text}
      </FormattedMessage>
    );
  }

  /**
   * clearPaymentForm
   *
   * @returns {void}
   */
  clearPaymentForm(): void {}
}
