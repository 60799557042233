// @flow

import React from 'react';
import type { Node } from 'react';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import { components as CONFIG_COMPONENTS, payment as CONFIG_PAYMENT } from 'Config';
import { cart as ENUM_CART, order as ENUM_ORDER } from 'Enum';
import type { PaymentMethod, PaymentMethodData } from 'shared_hocs/cart/payments/IPaymentMethod';
import {
  SUPPORTED_CARDS,
  STRIPE_TO_API_CARD_MAPPER,
  DISPLAY_SUPPORTED_PAYMENTS,
  StripeForm,
} from 'shared_components/checkout/sections/payment/types/Stripe';
import { StripeProvider } from 'shared_components/checkout/sections/payment/types/StripeProvider';
import { StripePaymentDescription } from 'shared_components/checkout/sections/payment/description/Stripe';
import { PaymentBase } from 'shared_hocs/cart/payments/Base';
import { RiseartLogger } from 'shared_services/riseart/Logger';
import { PaymentDetailStripe } from 'shared_components/cart/sections/payment/Stripe';

/**
 * StripeAdapter
 */
export class StripeAdapter extends PaymentBase implements PaymentMethod {
  props: Object;

  state: Object;

  handleError: Function;

  successCallback: Function;

  setState: Function;

  stripePaymentMethod: ?Object;

  /**
   * settings
   */
  static settings: Object = {
    internalValidation: false,
    rent: { canSaveCard: false, canUseSavedCards: true },
    buy: { canSaveCard: true, canUseSavedCards: true },
  };

  /**
   * getPaymentMethodName
   *
   * @returns {string}
   */
  static getPaymentMethodName(): string {
    return ENUM_CART.payment.method.METHOD_STRIPE;
  }

  /**
   * isEnabled
   *
   * @returns {boolean}
   */
  static isEnabled(): boolean {
    return CONFIG_PAYMENT.stripe.enabled;
  }

  /**
   * canSaveCard
   *
   * @param {string} paymentType
   * @returns {boolean}
   */
  canSaveCard(paymentType?: string): boolean {
    const settings = StripeAdapter.settings[this.props.cartType];
    const currentPaymentType = paymentType || this.state.payment.type;

    return !!(
      settings &&
      settings.canSaveCard &&
      currentPaymentType === ENUM_CART.paymentType.TYPE_CARD
    );
  }

  /**
   * canUseSavedPayments
   *
   * @param {'buy' | 'rent'} cartType
   * @returns {boolean}
   */
  static canUseSavedPayments(cartType: 'buy' | 'rent'): boolean {
    const settings = StripeAdapter.settings[cartType];

    return settings && settings.canUseSavedCards;
  }

  /**
   * getSupportedCards
   *
   * @param {boolean} showAsArray
   * @returns {Object}
   */
  static getSupportedCards(showAsArray: boolean = false): Object {
    return showAsArray
      ? Object.keys(SUPPORTED_CARDS).reduce(
          (acc, cardKey) => [...acc, SUPPORTED_CARDS[cardKey]],
          [],
        )
      : SUPPORTED_CARDS;
  }

  /**
   * displaySupportedPayments
   *
   * @returns {Array<Object>}
   */
  static displaySupportedPayments(): Array<Object> {
    return DISPLAY_SUPPORTED_PAYMENTS;
  }

  /**
   * hasInternalValidation
   *
   * Defines if payment method card data is handled by the code, or a third party library
   * In the case of Stripe alll card data is handled by their library
   * and no sensitive data is handled by the application
   *
   * @returns {boolean}
   */
  static hasInternalValidation(): boolean {
    return StripeAdapter.settings.internalValidation;
  }

  /**
   * renderBillingInfo
   *
   * @param {Object} checkoutProps
   * @param {Object} billingData
   * @returns {Object}
   */
  static renderBillingInfo(checkoutProps: Object, billingData: Object): Object {
    return <StripePaymentDescription checkoutProps={checkoutProps} billingData={billingData} />;
  }

  /**
   * getPaymentForm
   *
   * @returns {Object}
   */
  static getPaymentForm(): Object {
    return StripeForm;
  }

  /**
   * getPaymentProvider
   *
   * @returns {Object}
   */
  static getPaymentProvider(): Object {
    return StripeProvider;
  }

  /**
   * enhanceCardData
   */
  static enhanceCardData(): Object {
    return {};
  }

  /**
   * mapPaymentIntentStatus
   *
   * @param {string} status
   * @returns
   */
  static mapPaymentIntentStatus(status: string): string {
    const MAPPER = {
      requires_payment_method: StripeAdapter.PAYMENT_STATUS.CANCELED,
      succeeded: StripeAdapter.PAYMENT_STATUS.COMPLETE,
    };

    return MAPPER[status] || StripeAdapter.PAYMENT_STATUS.PROCESSING;
  }

  /**
   * delayReadOrder
   *
   * @param {Object} inputParams
   * @returns {number}
   */
  static delayReadOrder(inputParams: Object): number {
    return inputParams && inputParams.redirect_status === StripeAdapter.PAYMENT_STATUS.FAILED
      ? CONFIG_COMPONENTS.order.readQueryTimeout
      : 0;
  }

  /**
   * renderPaymentInformation
   *
   * @param {Object} data
   * @returns {Node}
   */
  static renderPaymentInformation(data: Object): Node {
    return <PaymentDetailStripe order={data} />;
  }

  /**
   * isReady
   *
   * @param {Object} providerOptions
   * @returns {boolean}
   */
  static isReady(providerOptions: Object): boolean {
    return !!(providerOptions && providerOptions.stripe);
  }

  /**
   * constructor
   *
   * @param {Object} props
   * @param {Object} state
   * @param {Function} handleError
   */
  constructor(
    props: Object,
    state: Object,
    handleError: Function,
    successCallback: Function,
    setState: Function,
  ) {
    super();
    this.props = props;
    this.state = state;
    this.handleError = handleError;
    this.successCallback = successCallback;
    this.setState = setState;
  }

  /**
   * hydrate
   *
   * @param {Object} data
   * @return {Object}
   */
  hydrate(data: Object): Object {
    Object.keys(data).forEach((key: string) => {
      // $FlowFixMe
      this[key] = data[key];
    });

    return this;
  }

  /**
   * getOrderVariables
   *
   * @param {Props} props
   * @param {State} state
   * @returns {Object}
   */
  async getOrderVariables(props: Object, state: Object): Object {
    const { stripe, elements, cart } = props;
    const { billing } = state;

    // Does not require payment
    if (cart.totalAmount <= 0) {
      return { error: false };
    }

    // Uses saved payment card
    if (billing.data.userPaymentId) {
      return { error: false };
    }

    // Stripe.js has not loaded yet
    if (!stripe || !elements) {
      this.handleError({ message: 'components.checkout.notLoadedStripe' });
      return { error: true };
    }

    // Has a saved stripePaymentMethod (return from 3D Secure card authentication)
    if (this.stripePaymentMethod) {
      return {
        paymentId: this.stripePaymentMethod.id,
        cardLastDigits:
          this.stripePaymentMethod &&
          this.stripePaymentMethod.card &&
          this.stripePaymentMethod.card.last4,
      };
    }

    this.props.setPageLoading(true);
    const { data: billingData } = billing;

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      this.handleError(submitError);
      return { error: true };
    }

    const payload = await stripe.createPaymentMethod({
      elements,
      params: {
        billing_details: {
          email: billingData.email,
          name: [billingData.firstname, billingData.lastname].filter((item) => !!item).join(' '),
          phone: billingData.telephone,
          address: {
            ...(billingData.city ? { city: billingData.city } : {}),
            ...(billingData.countryCode ? { country: billingData.countryCode } : {}),
            ...(billingData.streetAddress1 ? { line1: billingData.streetAddress1 } : {}),
            ...(billingData.streetAddress2 ? { line2: billingData.streetAddress2 } : {}),
            // $FlowFixMe
            ...(billingData.county ? { state: billingData.county } : {}),
            ...(billingData.postcode ? { postal_code: billingData.postcode } : {}),
          },
        },
      },
    });

    if (!payload || payload.error || !payload.paymentMethod) {
      const error = !payload ? { message: 'components.checkout.declinedPayment' } : payload.error;
      this.handleError(error);

      return { error };
    }

    this.props.setPageLoading(false);
    const { paymentMethod } = payload;

    // Store payment method for later use (in 3d secure authentication for example)
    this.setStripePaymentMethod(paymentMethod);

    return {
      paymentId: paymentMethod.id,
      cardType:
        STRIPE_TO_API_CARD_MAPPER[paymentMethod && paymentMethod.card && paymentMethod.card.brand],
    };
  }

  /**
   * createOrderErrorHandler
   *
   * @param {Object} response
   */
  async createOrderErrorHandler(response: Object) {
    if (!response.graphQLErrors) {
      this.setStripePaymentMethod(null);
      this.props.setPageLoading(false);
      return;
    }

    this.props.setPageLoading(true);
    const paymentIntentSecret = response.graphQLErrors.reduce((accumulator, item) => {
      if (item.errorInfo.status === 401 && item.errorInfo.code === '35001') {
        return get(
          item.errorInfo,
          `additional.${ENUM_CART.payment.method.METHOD_STRIPE}.paymentIntentSecret`,
          null,
        );
      }

      return accumulator;
    }, null);

    if (paymentIntentSecret) {
      const { stripe } = this.props;

      try {
        const { error: paymentIntentError, paymentIntent } =
          await stripe.retrievePaymentIntent(paymentIntentSecret);

        if (paymentIntentError) {
          this.setStripePaymentMethod(null);
          this.handleError(paymentIntentError);
          return;
        }

        // Payment requies additional actions (3D secure authentication, redirect to payment provider website, etc.)
        if (paymentIntent.status === 'requires_action') {
          this.props.setPageLoading(true);

          if (paymentIntent.confirmation_method === 'manual') {
            const response = await stripe.handleNextAction({ clientSecret: paymentIntentSecret });
            this.handleStripePaymentCallback(response);
          } else {
            throw new Error('Stripe payment intent confirmation method is not set to manual');
          }
        }
      } catch (error) {
        this.setStripePaymentMethod(null);

        // Log error as exception
        RiseartLogger.exception(error);

        // Show a user message about declined payment
        this.handleError({ message: 'components.checkout.declinedPayment' });
      }
    } else {
      this.setStripePaymentMethod(null);
      this.props.setPageLoading(false);
    }
  }

  /**
   * handleStripePaymentCallback
   *
   * @param {Object} response
   */
  handleStripePaymentCallback: Function = (response: Object) => {
    if (response.error) {
      this.setStripePaymentMethod(null);
      this.handleError(response.error);
      return;
    }

    this.placeOrder();
  };

  /**
   * placeOrder
   *
   * @param {Function} successCallback
   * @returns {Promise<any>}
   */
  async placeOrder(): Promise<any> {
    try {
      const { cart, isRent, marketingCampaignId } = this.props;
      const { shipping, billing, legalAgreementId } = this.state;
      const { error, ...createOrderVariables } =
        (await this.getOrderVariables(this.props, this.state)) || {};

      if (error) {
        return;
      }

      return this.props.onCreateOrder(
        {
          inputCheckout: {
            savePayment: billing.data.savePayment || false,
            saveShippingAddress: shipping.data.saveShippingAddress || false,
            // $FlowFixMe
            ...(isRent ? { legalAgreementId } : null),
            ...(marketingCampaignId ? { marketingCampaignId } : null),
          },
          inputPayment: {
            ...(this.state.payment.type && this.requiresPayment
              ? { paymentType: this.state.payment.type }
              : {}),
            ...(this.requiresPayment ? { returnUrlTemplate: this.props.returnUrl } : {}),
            ...(cart.totalAmount ? { method: ENUM_CART.payment.method.METHOD_STRIPE } : {}),
            ...(!billing.data.userPaymentId
              ? {
                  ...createOrderVariables,
                }
              : { userPaymentId: billing.data.userPaymentId }),
            totalAmount: cart.totalAmount,
          },
        },
        this.successCallback,
        this.createOrderErrorHandler.bind(this),
      );
    } catch (error) {
      // Log error as exception
      RiseartLogger.exception(error);

      // Show a user message about declined payment
      this.handleError({ message: 'components.checkout.declinedPayment' });
    }
  }

  /**
   * setStripePaymentMethod
   *
   * @param {Object | null} value
   * @returns {void}
   */
  setStripePaymentMethod(value: Object | null): void {
    this.stripePaymentMethod = value;
  }

  /**
   * getPaymentMethodData
   *
   * @param {Object} inputParams
   * @returns {Promise<PaymentMethodData | null>}
   */
  async getPaymentMethodData(inputParams: Object = {}): Promise<PaymentMethodData | null> {
    const { payment_intent_client_secret: paymentIntentClientSecret } = inputParams || {};

    if (!this.props.stripe || !paymentIntentClientSecret) {
      return null;
    }

    try {
      const response = await this.props.stripe.retrievePaymentIntent(paymentIntentClientSecret);

      return response && response.paymentIntent
        ? {
            error: response.paymentIntent.last_payment_error,
            status: StripeAdapter.mapPaymentIntentStatus(response.paymentIntent.status),
          }
        : response;
    } catch (error) {
      return {
        error,
        status: StripeAdapter.PAYMENT_STATUS.ERROR,
      };
    }
  }

  /**
   * requiresPayment
   *
   * @returns {boolean}
   */
  get requiresPayment(): boolean {
    return this.props.cart.totalAmount > 0;
  }

  /**
   * shouldCancelOrder
   *
   * @param {Object} inputParams
   * @returns {boolean}
   */
  async shouldCancelOrder(inputParams: Object): Promise<boolean> {
    const response = await this.getPaymentMethodData(inputParams);

    return response ? response.status === StripeAdapter.PAYMENT_STATUS.CANCELED : false;
  }

  /**
   * shouldTriggerConfirmationEvent
   *
   * @param {Object} order
   * @param {boolean} hasValidPaymentIntent
   * @returns {boolean}
   */
  shouldTriggerConfirmationEvent(order: Object, hasValidPaymentIntent: boolean): boolean {
    return !!(
      hasValidPaymentIntent &&
      order &&
      order.orderDate &&
      Date.parse(order.orderDate) > Date.now() - CONFIG_PAYMENT.stripe.confirmLeeway * 1000
    );
  }

  /**
   * shouldRedirectFromNextAction
   *
   * @param {string} nextAction
   * @param {Object} qsParams
   * @returns {boolean}
   */
  shouldRedirectFromNextAction(nextAction: string, qsParams: Object): boolean {
    const { payment_intent_client_secret: paymentIntentClientSecret } = qsParams || {};

    return nextAction === StripeAdapter.ORDER_NEXT_STEPS.CONFIRM && !!paymentIntentClientSecret;
  }

  /**
   * getOrderNextAction
   *
   * @param {Object} inputParams
   * @param {Object} order
   * @return {Promise<{nextAction: string, triggerConfirmationEvent: boolean}>}
   */
  async getOrderNextAction(
    inputParams: Object,
    order: Object,
  ): Promise<{ nextAction: string, triggerConfirmationEvent: boolean }> {
    const paymentIntent = await this.getPaymentMethodData(inputParams);
    const hasValidPaymentIntent = !!(
      paymentIntent && paymentIntent.status !== StripeAdapter.PAYMENT_STATUS.ERROR
    );
    const paymentIntentStatus = paymentIntent && paymentIntent.status;
    const isOrderCanceled = order.state === ENUM_ORDER.state.STATE_CANCELED;
    const isOrderInPaymentReview = order.state === ENUM_ORDER.state.STATE_PAYMENT_REVIEW;
    const {
      CONFIRM: STEP_CONFIRM,
      CANCEL: STEP_CANCEL,
      CART: STEP_CART,
    } = StripeAdapter.ORDER_NEXT_STEPS;

    // Order is not canceled and is not in payment_review
    if (!isOrderCanceled && !isOrderInPaymentReview) {
      return {
        nextAction: STEP_CONFIRM,
        triggerConfirmationEvent: this.shouldTriggerConfirmationEvent(order, hasValidPaymentIntent),
        redirect: this.shouldRedirectFromNextAction(STEP_CONFIRM, inputParams),
      };
    }

    // Order is canceled or in payment review and there is no valid payment intent
    if (!hasValidPaymentIntent) {
      return {
        nextAction: STEP_CONFIRM,
        triggerConfirmationEvent: false,
        redirect: this.shouldRedirectFromNextAction(STEP_CONFIRM, inputParams),
      };
    }

    // Order is canceled or in payment review and there is a valid payment intent which requires payment methods
    if (hasValidPaymentIntent && paymentIntentStatus === StripeAdapter.PAYMENT_STATUS.CANCELED) {
      return {
        nextAction: STEP_CANCEL,
        triggerConfirmationEvent: false,
        redirect: this.shouldRedirectFromNextAction(STEP_CANCEL, inputParams),
      };
    }

    // Order is canceled or in payment review and there is a valid payment intent which does not requires payment methods
    if (hasValidPaymentIntent && paymentIntentStatus !== StripeAdapter.PAYMENT_STATUS.CANCELED) {
      // Redirect to cart if order is canceled
      if (isOrderCanceled) {
        return {
          nextAction: STEP_CART,
          triggerConfirmationEvent: false,
          redirect: this.shouldRedirectFromNextAction(STEP_CART, inputParams),
        };
      }

      // Redirect to confirmation page with additional information
      return {
        nextAction: STEP_CONFIRM,
        triggerConfirmationEvent: this.shouldTriggerConfirmationEvent(order, hasValidPaymentIntent),
        redirect: this.shouldRedirectFromNextAction(STEP_CONFIRM, inputParams),
      };
    }

    // In any other case always return to confirmation page
    return {
      nextAction: STEP_CONFIRM,
      triggerConfirmationEvent: false,
      redirect: this.shouldRedirectFromNextAction(STEP_CONFIRM, inputParams),
    };
  }

  /**
   * getCheckoutButtonText
   *
   * @returns {Node}
   */
  getCheckoutButtonText(): Node {
    const paymentType = get(this.state, 'payment.type');

    return (
      <FormattedMessage
        id={`components.checkout.${
          paymentType && paymentType !== ENUM_CART.paymentType.TYPE_CARD
            ? 'checkoutButtonExternal'
            : 'placeOrder'
        }`}
        values={{
          paymentType: paymentType ? (
            <FormattedMessage
              key="paymentType"
              id={`components.checkout.paymentType.${paymentType}`}
              defaultMessage="stripe"
            >
              {(text: string) => text}
            </FormattedMessage>
          ) : null,
        }}
      >
        {(text: string) => text}
      </FormattedMessage>
    );
  }

  /**
   * clearPaymentForm
   *
   * @returns {void}
   */
  clearPaymentForm(): void {
    if (this.props && this.props.elements) {
      this.props.elements.getElement('payment').clear();
    }
  }
}
