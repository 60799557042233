// @flow

import type { ComponentType } from 'react';
import { connect } from 'react-redux';
import { clearPreloaderState } from 'shared_services/redux/actions/application/pagepreload';
import { store as STORE_CONFIG } from 'Config';

/**
 * mapDispatchToProps
 *
 * @param dispatch
 * @returns {Object}
 */
function mapDispatchToProps(dispatch: Function): Object {
  return {
    actionClearPreloaderState: () => {
      dispatch(clearPreloaderState());
    },
  };
}

/**
 * mapStateToProps
 *
 * @param state
 * @returns {Object} state to props
 */
function mapStateToProps(state): Object {
  const subscribers = state[STORE_CONFIG.keys.pagePreloader];

  return {
    subscribers,
  };
}

/**
 * HOCPagePreloader
 * connected to pagePreloader state in redux store
 * @param {ComponentType<*>} Preloader
 */
export function HOCPagePreloader(Preloader: ComponentType<*>): ComponentType<*> {
  return connect<*, *, *, *, *, *>(mapStateToProps, mapDispatchToProps)(Preloader);
}
