// @flow

import React, { Component } from 'react';
import type { ComponentType } from 'react';
import { store as CONFIG_STORE } from 'Config';
import { WithStore } from 'shared_hocs/connect/withStore';

const HOC_DISPLAY_NAME = 'withMeState';

/**
 * defaultMapStateToProp
 * @param {*} meState
 * @return {Object}
 */
const defaultMapStateToProp = (meState: Object): Object => ({
  me: meState.data,
  error: meState.error,
  status: meState.status,
});

/**
 * propsMapper
 * @param {Function} meStateMapper
 * @returns {Function}
 */
const propsMapper =
  (meStateMapper: Function) =>
  (state: Object): Object => {
    const meState = state[CONFIG_STORE.keys.me];

    return meStateMapper(meState, state);
  };

/**
 * withMeState
 * @param {Class<Component<*, *, *>>} DecoratedComponent
 * @param {Function} mapStateToProps
 */
export function withMeState(
  DecoratedComponent: ComponentType<*>,
  meStateMapper?: Function,
  mapDispatchToProps?: Function | Object,
) {
  return class extends Component<*, *> {
    static displayName = HOC_DISPLAY_NAME;

    /**
     * render
     * @returns {React$Element}
     */
    render() {
      return (
        <WithStore
          mapStateToProps={propsMapper(meStateMapper || defaultMapStateToProp)}
          {...(mapDispatchToProps ? { mapDispatchToProps } : {})}
        >
          {({ mapStateToProps, mapDispatchToProps, ...restProps }) => (
            <DecoratedComponent {...this.props} {...restProps} />
          )}
        </WithStore>
      );
    }
  };
}
