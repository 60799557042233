// @flow

import React, { Fragment } from 'react';
import type { Node } from 'react';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { Section, Wrapper } from '@riseart/layout';
import { useQuery } from 'shared_services/apollo/useQuery';
import { LDTYPES, generateLDJSON } from 'shared_models/seo/ldJSON';
import { convertPayloadToQueryVars } from 'shared_services/riseart/utils/Utils';
import { LocationManager } from 'shared_services/riseart/url/Location';
import { useStoreCode } from 'shared_services/redux/hooks/useStoreCode';
import { selectByStoreKey } from 'shared_services/redux/selectors/byKey';
import { filterDomainToStoreKey } from 'shared_hocs/filters/withFilterState';
import { FilterBreadcrumbs } from 'shared_components/common/filters/breadcrumbs/Breadcrumbs';
import { Category } from 'shared_components/common/category/Category';
import FILTER_META_CATEGORIES_QUERY from 'shared_data/queries/filter/metaCategories.graphql';
import { META_SUBSCRIBER_NAME } from 'shared_services/riseart/meta/Meta';
import { meta as META_ENUM } from 'Enum';
import { MetaProvider } from 'shared_data/providers/meta/Meta';

import { filterBreadcrumbsSectionCls } from 'shared_components/common/filters/breadcrumbs/Breadcrumbs.less';

type Props = {
  domain: string,
  config: Array<string>,
};

/**
 * MetaCategoryContainer
 *
 * @param {Props} props
 * @returns {Node}
 */
export function MetaCategoryContainer({ domain, config }: Props): Node {
  const {
    PAGE_TITLE,
    META_TITLE,
    META_KEYWORDS,
    META_DESCRIPTION,
    META_ROBOTS,
    SCRIPT_LD_JSON_AGGREGATE_RATING,
  } = META_ENUM.METATYPE;
  const storeCode = useStoreCode();
  const { filters } = useSelector(selectByStoreKey(filterDomainToStoreKey[domain])) || {
    filters: {},
  };
  const { error, loading, data } = useQuery(FILTER_META_CATEGORIES_QUERY, {
    skip: !storeCode || !filters || !domain,
    errorPolicy: 'all',
    variables: {
      inputMetaCategoryFilter: {
        domain,
        ...convertPayloadToQueryVars(
          { referrer: LocationManager.get('uri'), ...filters },
          storeCode,
        ),
      },
    },
    context: {
      customOptions: {
        errorSuppressFromResponse: true,
        errorFilter: (errorList) =>
          errorList && errorList.filter((error) => error.errorType !== 'ResourceNotFoundException'),
      },
    },
  });
  const breadcrumbsSection = (
    <Section className={filterBreadcrumbsSectionCls} padding="none">
      <Wrapper>
        <FilterBreadcrumbs filterDomain={domain} filters={filters} config={config} />
      </Wrapper>
    </Section>
  );

  if (!storeCode || !filters || !domain) {
    return breadcrumbsSection;
  }

  const categoryData = (!error && data && data.filterMetaCategories) || null;
  const hasError = !!error || !categoryData;
  const category404Error =
    error &&
    error.graphQLErrors.some((err) => err && err.errorInfo && err.errorInfo.status === 404);
  const noIndexTag =
    !loading && (category404Error || !categoryData) && Object.keys(filters || {}).length
      ? {
          [PAGE_TITLE]: {
            disabled: true,
            text: get(categoryData, 'pageTitle', null),
          },
          [META_TITLE]: get(categoryData, 'metaTitle', null),
          [META_DESCRIPTION]: get(categoryData, 'metaDescription', null),
          [META_KEYWORDS]: get(categoryData, 'metaKeywords', null),
          [META_ROBOTS]: META_ENUM.ATTRIBUTE_CONTENT.NO_INDEX,
        }
      : null;

  return (
    <Fragment>
      <MetaProvider
        forceResolution
        cacheState={false}
        metaSubscriber={META_SUBSCRIBER_NAME.FILTER_CATEGORY_META}
        meta={
          categoryData
            ? {
                [PAGE_TITLE]: {
                  disabled: true,
                  text: get(categoryData, 'pageTitle', null),
                },
                [META_TITLE]: get(categoryData, 'metaTitle', null),
                [META_DESCRIPTION]: get(categoryData, 'metaDescription', null),
                [META_KEYWORDS]: get(categoryData, 'metaKeywords', null),
                [SCRIPT_LD_JSON_AGGREGATE_RATING]: generateLDJSON(
                  LDTYPES.AGGREGATE_RATING,
                  get(categoryData, 'title', null),
                ),
              }
            : noIndexTag
        }
        hasError={hasError && !noIndexTag}
      />
      {categoryData ? (
        <Category
          id={categoryData.id}
          image={categoryData.images}
          title={categoryData.title}
          description={categoryData.description}
        >
          <FilterBreadcrumbs filterDomain={domain} filters={filters} config={config} />
        </Category>
      ) : (
        breadcrumbsSection
      )}
    </Fragment>
  );
}
