// @flow

import { Component } from 'react';

type Props = {
  text: string,
  children: Function,
};

type State = { length: number };

function calcMaxWordLength(text) {
  if (typeof text === 'string') {
    return text.split(/[ -]+/).reduce((maxLength, word) => {
      const { length } = word;

      return length > maxLength ? length : maxLength;
    }, 0);
  }

  return 0;
}

/**
 * WithMaxWordLength
 */
export class WithMaxWordLength extends Component<Props, State> {
  /**
   * constructor
   *
   * @param {Props} props
   */
  constructor(props: Props) {
    super(props);

    this.state = { length: 0 };
  }

  /**
   * getDerivedStateFromProps
   *
   * @param {Props} props
   * @param {State} state
   */
  static getDerivedStateFromProps(props: Props, state: State) {
    const maxLength = calcMaxWordLength(props.text);

    return maxLength !== state.length ? { length: maxLength } : null;
  }

  /**
   * render
   */
  render() {
    const { children } = this.props;

    return children(this.state.length);
  }
}
