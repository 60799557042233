// @flow

import React, { Component } from 'react';
import { WithStore } from 'shared_hocs/connect/withStore';
import { store as CONFIG_STORE } from 'Config';

const HOC_DISPLAY_NAME = 'withErrorsState';

/**
 * defaultMapStateToProp
 * @param {Array<Object>} errorState
 * @return {Object}
 */
const defaultMapStateToProp = (errorState: Array<Object>): Object => ({
  errors: errorState,
});

/**
 * propsMapper
 * @param {Function} errorStateMapper
 * @returns {Function}
 */
const propsMapper =
  (errorStateMapper: Function) =>
  (state: Object, ownProps: Object): Object => {
    const errorState = state[CONFIG_STORE.keys.errors];

    return errorStateMapper(errorState, ownProps);
  };

/**
 * withErrorState
 * @param {Class<Component<*, *, *>>} DecoratedComponent
 * @param {Function} mapStateToProps
 */
export function withErrorState(
  DecoratedComponent: Class<Component<*, *, *>>,
  stateMapper?: Function,
  mapDispatchToProps?: Function | Object,
) {
  return class extends Component {
    static displayName = HOC_DISPLAY_NAME;

    /**
     * render
     * @returns {React$Element}
     */
    render() {
      return (
        <WithStore
          {...this.props}
          mapStateToProps={propsMapper(stateMapper || defaultMapStateToProp)}
          {...(mapDispatchToProps ? { mapDispatchToProps } : {})}
        >
          {(props) => <DecoratedComponent {...this.props} {...props} />}
        </WithStore>
      );
    }
  };
}
