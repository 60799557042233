// @flow

import React, { Component } from 'react';
import type { ComponentType } from 'react';
import { connect } from 'react-redux';
import { cart as ENUM_CART } from 'Enum';
import { Query } from 'shared_services/apollo/Query';
import { selectVisitorProperty, selectVisitorId } from 'shared_services/redux/selectors/visitor';
import { selectStoreCode } from 'shared_services/redux/selectors/storeCode';
import { selectAuthShippingCountryCode } from 'shared_services/redux/selectors/auth';
import { selectUnitSystem } from 'shared_services/redux/selectors/unitSystem';
import { LocalizedConfig } from 'shared_services/riseart/utils/LocalizedConfig';
import READ_CART_QUERY from 'shared_data/queries/cart/read.graphql';

const HOC_DISPLAY_NAME = 'HOCCart';

type Props = Object;
type State = { loading: boolean };

/**
 * HOCCartComponent
 *
 * @param {ComponentType<*>} DecoratedComponent
 */
const HOCCartComponent = (DecoratedComponent: ComponentType<*>) =>
  class extends Component<Props, State> {
    static displayName = HOC_DISPLAY_NAME;

    initialLoad: boolean;

    /**
     * constructor
     *
     * @param {*} props
     */
    constructor(props: Props) {
      super(props);

      this.state = { loading: false };
      this.bindMethods();
      this.initialLoad = true;
    }

    /**
     * bindMethods
     */
    bindMethods() {
      this.handleLoading = this.handleLoading.bind(this);
    }

    /**
     *
     * @param {boolean} loading
     */
    handleLoading: Function;

    handleLoading(loading: boolean) {
      this.setState({ loading });
    }

    /**
     * render
     */
    render() {
      const {
        cartType: cartTypeProp,
        match,
        visitorId,
        storeCode: store,
        forceRefetch = true,
        currentLocale,
      } = this.props;
      const cartType =
        cartTypeProp ||
        (match &&
        match.params &&
        match.params.cartType &&
        currentLocale &&
        LocalizedConfig.get(`components.cart.routeToType.${match.params.cartType}`) ===
          ENUM_CART.type.TYPE_RENT
          ? ENUM_CART.type.TYPE_RENT
          : ENUM_CART.type.TYPE_BUY);

      return (
        <Query
          skip={!store || !visitorId}
          query={READ_CART_QUERY}
          variables={{ cartId: cartType, store, visitorId }}
          {...(this.initialLoad && forceRefetch ? { fetchPolicy: 'network-only' } : {})}
        >
          {({ loading, error, data }) => {
            const { readCart: responseData = {} } = data || {};
            const { cartItems = [], ...restData } = responseData;
            this.initialLoad = false;

            return (
              <DecoratedComponent
                {...this.props}
                queryRequest={{ loading, error }}
                loading={this.state.loading}
                cart={restData}
                cartItems={cartItems}
                cartType={cartType}
                handleLoading={this.handleLoading}
              />
            );
          }}
        </Query>
      );
    }
  };

/**
 * mapStateToProps
 *
 * @param {Object} state
 * @returns {Object} mapped props from state
 */
function mapStateToProps(state: Object): Object {
  const authStateVisitorId = selectVisitorId(state);
  const visitorId = selectVisitorProperty('id')(state) || {};
  const storeCode = selectStoreCode(state);
  const shippingCountryCode = selectAuthShippingCountryCode(state);
  const { unitSystem } = selectUnitSystem(state);

  if (!visitorId) {
    return {
      visitorId: null,
      storeCode: null,
      shippingCountryCode: null,
    };
  }

  // Get visitor from auth state and compare if the auth visitor is same as me visitor
  // because in case of redirect from login page the readMe might have not yet updated
  // the visitor information after an auth update
  const authVisitorId = parseInt(authStateVisitorId, 10);
  const meVisitorId = parseInt(visitorId, 10);

  const isVisitorSynced =
    (!authVisitorId && meVisitorId) || (authVisitorId && authVisitorId === meVisitorId);

  if (isVisitorSynced) {
    return {
      visitorId: meVisitorId,
      storeCode,
      unitSystem,
      shippingCountryCode,
    };
  }

  return {
    visitorId: null,
    storeCode: null,
    unitSystem,
    shippingCountryCode: null,
  };
}

export const HOCCart = (CartPageComponent: ComponentType<*>) =>
  connect<*, *, *, *, *, *>(mapStateToProps)(HOCCartComponent(CartPageComponent));
