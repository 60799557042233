// @flow

import type { Node } from 'react';
import { useLocale } from 'shared_services/redux/hooks/useLocale';

type Props = {
  property?: string,
  children: Function,
};

/**
 * WithLocale
 *
 * @param {Props} props
 * @returns {Node}
 */
export const WithLocale = ({ children, property }: Props): Node => {
  const locale = useLocale();

  return children(property && locale ? locale[property] : locale);
};
