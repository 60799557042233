// @flow

import React, { useState, useCallback, useEffect, useMemo, useRef, memo } from 'react';
import { FilterSlider as FilterSliderComponent, FilterSliderItem } from '@riseart/filter';
import { areDifferent } from 'shared_services/riseart/utils/Utils';

type Props = Object;

type State = {
  range: Object,
  marks: Array<any>,
  defaultValues: Array<any>,
  tipFormatter: Function | null,
};

const DEFAULT_STATE = { range: { min: 0, max: 0 }, marks: [], defaultValues: [] };
/**
 * getParameters
 *
 * @param props
 * @returns {{marks: {}, range: {}, defaultValues: Array}}
 */
function getParameters(props: Props = DEFAULT_STATE): State {
  const marks = props.options.reduce(
    (accumulator, option, index) => ({
      ...accumulator,
      [index]: option.label && option.label.trim ? option.label.trim() : '',
    }),
    {},
  );
  const markKeys = Object.keys(marks).map((mark) => parseInt(mark, 10));

  return {
    marks,
    range: {
      min: Math.min(...markKeys),
      max: Math.max(...markKeys),
    },
    defaultValues: (props.options || []).reduce(
      (accumulator, option, index) => (option.selected ? [...accumulator, index] : accumulator),
      [],
    ),
    tipFormatter: props.tipFormatter || null,
  };
}

/**
 * Expendable filter state and data manager for the Ant slider components
 *
 * @param Slider
 * @returns {{displayName, new(*=): {bindMethods(), render(): XML, getDefaultState(): {errorNotificationPayload: null}}}}
 * @constructor
 */
export const FilterSlider: Function = memo(
  (props: Props) => {
    const { onChange, dimension, options } = props;
    const currentProps = useRef(props);
    const [filterState, setFilterState] = useState<State>(getParameters(props));
    const prevFilterState: Object = useRef([]);

    useEffect(() => {
      if (
        areDifferent(props.options, currentProps.current.options) ||
        currentProps.current.unitLength !== props.unitLength
      ) {
        const { marks, range, defaultValues, tipFormatter } = getParameters(props);
        setFilterState({
          range: {
            min: range.min,
            max: range.max,
          },
          marks,
          defaultValues,
          tipFormatter,
        });
      }

      currentProps.current = props;
    }, [props]);

    prevFilterState.current = useMemo(() => getParameters(props).defaultValues, [props]);

    /**
     * onAfterChangeHandler
     *
     * @param values
     */
    const onAfterChangeHandler = useCallback(
      (values: Array<string>) => {
        prevFilterState.current = values;

        onChange(
          dimension,
          values.map((value) => options[value]),
        );
      },
      [onChange, dimension, options],
    );

    // A new instance must be passed each time because of issue with Antd component, that is not updated always with the new props
    const SliderInstance = () => (
      <FilterSliderComponent
        type={props.type} // eslint-disable-line
        minRange={filterState.range.min}
        maxRange={filterState.range.max}
        marks={props.marks && filterState.marks} // eslint-disable-line
        onAfterChangeHandler={onAfterChangeHandler}
        onChange={(values) => {
          prevFilterState.current = values;
        }}
        defaultValues={prevFilterState.current}
        tipFormatter={filterState.tipFormatter ? filterState.tipFormatter(props) : null}
      />
    );

    return (
      <FilterSliderItem
        title={props.title}
        text={
          props.rangeFormatter
            ? props.rangeFormatter(
                filterState.defaultValues.map((valueIndex) => props.options[valueIndex]),
                props,
              )
            : null
        }
      >
        <SliderInstance />
      </FilterSliderItem>
    );
  },
  (prevProps, nextProps) =>
    !(
      areDifferent(nextProps, prevProps) ||
      areDifferent(nextProps.options, prevProps.options) ||
      nextProps.storeCode !== prevProps.storeCode ||
      nextProps.unitLength !== prevProps.unitLength
    ),
);
