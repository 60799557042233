// @flow

import findIndex from 'lodash/findIndex';
import React, { Component } from 'react';
import { RedirectWithStatus } from 'shared_components/routers/RedirectWithStatus';

type Props = {
  loading: boolean,
  selectedArtDetailsId: number | string,
  items: Array<Object>,
  location: Object,
  children: Function,
};

/**
 * WithArtistArtDetails
 */
export class WithArtistArtDetails extends Component<Props> {
  /**
   * render
   */
  render() {
    const { selectedArtDetailsId, items = [], location, loading, children } = this.props;
    const itemsLength = items && items.length;

    const selectedId =
      (selectedArtDetailsId &&
        selectedArtDetailsId === 'first' &&
        ((!loading && items[0].id) || items[itemsLength - 1].id)) ||
      (selectedArtDetailsId &&
        selectedArtDetailsId === 'last' &&
        ((!loading && items[itemsLength - 1].id) || items[0].id)) ||
      selectedArtDetailsId;

    // If requested id is first or last, then get the actual id by position in item and redirect
    if ((selectedArtDetailsId === 'first' || selectedArtDetailsId === 'last') && !loading) {
      return (
        <RedirectWithStatus
          to={`${location.pathname}${location.search}#${encodeURIComponent(
            `detail=${selectedId}`,
          )}`}
        />
      );
    }

    const itemIdx = findIndex(items, (item) => item && item.id === selectedId);

    // Redirect to main requested page, if item is not found in the list of items
    if (itemIdx === -1) {
      return <RedirectWithStatus to={`${location.pathname}${location.search}`} />;
    }

    return children({
      items,
      itemIdx,
      selectedItem: items[itemIdx],
      itemPositionInPage: itemIdx + 1,
    });
  }
}
