// @flow

import React, { Component } from 'react';
import type { ComponentType } from 'react';
import { connect } from 'react-redux';
import { GOOGLE_TAGMANAGER_ACTIONS } from 'shared_data/providers/google/tagmanager/Actions';
import { actionFactory } from 'shared_services/riseart/utils/Utils';
import { withTranslatedRouter } from 'shared_data/providers/url/withTranslatedRouter';

const HOC_DISPLAY_NAME = 'HOCQuizRouteButton';

type Props = Object;

/**
 * HOCQuizRoute
 *
 * @param {ComponentType<*>} QuizRouteButton
 */
function HOCQuizRoute(QuizRouteButton: ComponentType<*>) {
  return class extends Component<Props> {
    static displayName = HOC_DISPLAY_NAME;

    /**
     * constructor
     *
     * @param {Props} props
     */
    constructor(props: Props) {
      super(props);
      this.bindMethods();
    }

    /**
     * render
     */
    render() {
      const {
        title,
        type,
        isAnchor,
        children,
        href,
        width,
        multiline,
        className = null,
      } = this.props;
      return (
        <QuizRouteButton
          title={title}
          type={type}
          href={href}
          isAnchor={isAnchor}
          onClick={this.quizStartHandler}
          className={className}
          {...(multiline ? { multiline } : {})}
          {...(width ? { width } : {})}
        >
          {children}
        </QuizRouteButton>
      );
    }

    /**
     * quizStartHandler
     */
    quizStartHandler: Function;

    quizStartHandler(e) {
      e.preventDefault();

      this.props.dispatch(
        actionFactory(GOOGLE_TAGMANAGER_ACTIONS.QUIZ_CLICK_START, this.props.trackData),
      );

      this.props.history.push(this.props.href);
    }

    /**
     * bindMethods
     */
    bindMethods() {
      this.quizStartHandler = this.quizStartHandler.bind(this);
    }
  };
}

/**
 * HOCQuizRouteButton
 *
 * @param {class} QuizRouteButton
 * @returns {React.Element}
 */
export default function HOCQuizRouteButton(QuizRouteButton: ComponentType<*>) {
  return connect<*, *, *, *, *, *>()(withTranslatedRouter(HOCQuizRoute(QuizRouteButton)));
}
