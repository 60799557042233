// @flow

import React, { Component } from 'react';
import { WithStore } from 'shared_hocs/connect/withStore';
import { filterDomainToStoreKey } from 'shared_hocs/filters/withFilterState';

type Props = {
  domain: string,
  children: Function,
};

/**
 * mapStateToProps
 *
 * Get filter state
 * @param {Object} state
 * @returns {Object}
 */
const mapStateToProps =
  (storeKey: string): Function =>
  (state: Object): Object =>
    state[storeKey] || {};

/**
 * WithFilterState
 */
export class WithFilterState extends Component<Props, *> {
  render() {
    return (
      <WithStore mapStateToProps={mapStateToProps(filterDomainToStoreKey[this.props.domain])}>
        {({ filters, ...rest }) => this.props.children(filters, rest)}
      </WithStore>
    );
  }
}
