// @flow

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

const Wrapper = ({ children, mapStateToProps, mapDispatchToProps, ...props }: Object) =>
  children(props);

/**
 * WithStore
 */
export const WithStore = connect(
  (state: Object, { mapStateToProps = () => ({}), ...restOwnProps }: Object): Object =>
    mapStateToProps(state, restOwnProps),
  (dispatch: Function, { mapDispatchToProps = () => ({}) }: Object): Object => {
    if (typeof mapDispatchToProps === 'object') {
      return bindActionCreators(mapDispatchToProps, dispatch);
    }

    return mapDispatchToProps(dispatch);
  },
)(Wrapper);
