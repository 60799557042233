// @flow

import type { Node } from 'react';
import { useSelector } from 'react-redux';
import { useAclAccess } from '@riseart/fe-utils';
import { application as CONFIG_APP } from 'Config';
import { getPageRouteConfig } from 'shared_services/riseart/utils/RouteUtils';
import { UrlAssembler } from 'shared_services/riseart/utils/UrlAssembler';
import { selectAclRole } from 'shared_services/redux/selectors/auth';
import { selectVisitorProperty } from 'shared_services/redux/selectors/visitor';

type Props = {
  isRental: boolean,
  isLoggedIn: Boolean,
  isUser: boolean,
  locale: Object,
  children: Function,
};

/**
 * CartCheckoutUrl
 * Connects to store and gets visitor.isUser to know if it has to redirect to registration or login form,
 * and it also checks if the acl role is allowed for the specific checkout page roles
 * and based on these it generates the proper checkout button url.
 * The children has to be a function that will eceive the checkoutUrl as parameter
 *
 * @param {Props} props
 */
export function WithCheckoutUrl({ isRental, children }: Props): Node {
  const forwardUrl = UrlAssembler.checkout(isRental ? 'rent' : 'buy');
  const isUser = useSelector(selectVisitorProperty('isUser')) || {};
  const aclRole = useSelector(selectAclRole);
  const { isAllowed } = useAclAccess({
    role: aclRole,
    resourcePermissions: getPageRouteConfig('checkout').acl.roles,
    rolesHierarchy: CONFIG_APP.acl.rolesHierarchy,
  });
  const checkoutUrl = isAllowed
    ? forwardUrl
    : UrlAssembler.checkoutAuth(isUser ? 'login' : 'join', {
        search: { forward: forwardUrl },
      });

  return children(checkoutUrl);
}
