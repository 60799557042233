// @flow

import { Component } from 'react';

const DEFAULT_TIMEOUT = 200;

type Props = {
  disabled: boolean,
  onLoading: Function,
  timeout?: number,
  children: Function,
};

type State = { loading: boolean };

/**
 * WithCartLoading
 */
export class WithCartLoading extends Component<Props, State> {
  static defaultProps: Object = { timeout: DEFAULT_TIMEOUT };

  loadingTimeout = null;

  /**
   * constructor
   *
   * @param {Props} props
   */
  constructor(props: Props) {
    super(props);

    this.state = { loading: false };
    this.bindMethods();
  }

  /**
   * componentWillUnmount
   */
  componentWillUnmount() {
    clearTimeout(this.loadingTimeout);
  }

  /**
   * bindMethods
   */
  bindMethods() {
    this.handleLoading = this.handleLoading.bind(this);
    this.updateLoading = this.updateLoading.bind(this);
  }

  /**
   * handleLoading
   *
   * @param {boolean} isLoading
   */
  handleLoading: Function;

  handleLoading(isLoading: boolean) {
    clearTimeout(this.loadingTimeout);

    if (isLoading === false) {
      this.updateLoading(isLoading);
    } else {
      this.loadingTimeout = setTimeout(
        () => this.updateLoading(isLoading),
        this.props.timeout || DEFAULT_TIMEOUT,
      );
    }
  }

  /**
   * updateLoading
   *
   * @param {boolean} isLoading
   */
  updateLoading: Function;

  updateLoading(isLoading: boolean) {
    this.setState({ loading: isLoading });

    if (typeof this.props.onLoading === 'function') {
      this.props.onLoading(isLoading);
    }
  }

  /**
   * render
   */
  render() {
    const { timeout, onLoading, children, ...restProps } = this.props;

    return children({ ...restProps, ...this.state, onLoading: this.handleLoading });
  }
}
