// @flow

import { cart as ENUM_CART } from 'Enum';
import { RiseartLogger } from 'shared_services/riseart/Logger';
import { StripeAdapter } from 'shared_hocs/cart/payments/Stripe';
import { DatacashAdapter } from 'shared_hocs/cart/payments/Datacash';
import { CustomerCreditAdapter } from 'shared_hocs/cart/payments/CustomerCredit';
import type { PaymentMethod } from 'shared_hocs/cart/payments/IPaymentMethod';

const MAPPER = {
  [ENUM_CART.payment.method.METHOD_STRIPE]: StripeAdapter,
  [ENUM_CART.payment.method.METHOD_DATACASH]: DatacashAdapter,
  [ENUM_CART.payment.method.METHOD_CREDIT]: CustomerCreditAdapter,
};

/**
 * PaymentMethodFactory
 */
export class PaymentMethodFactory {
  /**
   * getClass
   *
   * @param {string} defaultMethod
   * @param {string | null} currentMethod
   * @param {Object} params?
   * @param {boolean} checkEnebled?
   * @returns {PaymentMethod}
   */
  static getClass(
    defaultMethod?: string | null,
    currentMethod?: string | null,
    params?: Object = null,
    checkEnebled?: boolean = true,
  ): PaymentMethod {
    let selectedMethod =
      (currentMethod && MAPPER[currentMethod]) ||
      // $FlowFixMe
      (params && MAPPER[PaymentMethodFactory.detectPaymentMethod(params)]) ||
      null;

    if (selectedMethod && (selectedMethod.isEnabled() || !checkEnebled)) {
      return selectedMethod;
    }

    selectedMethod = defaultMethod && MAPPER[defaultMethod];

    if (selectedMethod && (selectedMethod.isEnabled() || !checkEnebled)) {
      return selectedMethod;
    }

    if (!selectedMethod) {
      RiseartLogger.exception(new Error('Invalid payment method.'), {
        scope: 'PaymentMethodFactory',
        param: 'paymentMethod',
        value: currentMethod,
      });
    }

    return selectedMethod;
  }

  /**
   * detectPaymentMethod
   *
   * @param {Object} params
   * @returns {string | null}
   */
  static detectPaymentMethod(params: Object): string | null {
    // Check if there is payment_intent_client_secret
    if (params && params.payment_intent_client_secret) {
      return ENUM_CART.payment.method.METHOD_STRIPE;
    }

    return null;
  }
}
