// @flow

import React, { Component } from 'react';
import { withTranslatedRouter } from 'shared_data/providers/url/withTranslatedRouter';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import { isRoleAllowedForACL } from '@riseart/fe-utils';
import { application as CONFIG_APP } from 'Config';
import { Query } from 'shared_services/apollo/Query';
import { localeSelector } from 'shared_services/redux/selectors/locale';
import { selectStoreCode } from 'shared_services/redux/selectors/storeCode';
import { selectUserId } from 'shared_services/redux/selectors/user';
import { selectVisitorId, selectVisitorProperty } from 'shared_services/redux/selectors/visitor';
import { selectAclRole } from 'shared_services/redux/selectors/auth';
import { withGUIState } from 'shared_hocs/gui/withGuiState';
import {
  gtmQuizQuestionLoaded,
  gtmQuizCompleted,
  gtmQuizTaken,
} from 'shared_data/providers/google/tagmanager/Actions';
import { GUI_PROPERTIES } from 'shared_models/Gui';
import { UrlAssembler } from 'shared_services/riseart/utils/UrlAssembler';
import { IsomorphicRipple } from 'shared_components/common/preloader/IsomorphicRipple';
import CREATE_RESPONSE_MUTATION from 'shared_data/queries/quiz/createResponse.graphql';
import LIST_QUIZ_PAGES_QUERY from 'shared_data/queries/quiz/listPages.graphql';

const HOC_DISPLAY_NAME = 'HOCQuiz';

type Props = {
  id: number,
  history: Object,
  visitorStoreCode: ?string,
  visitorIsSubscribed: boolean,
  visitorId: ?number,
  userId: ?number,
  aclRole: ?string,
  actionGuiUpdate: Function,
  pages: Array<?Object>,
  mutate: Function,
  actionGtmQuizQuestionLoaded: Function,
  actionGtmQuizCompleted: Function,
  actionGtmQuizTaken: Function,
  isLoading: boolean,
  locale: Object,
};

type State = {
  quizData?: Array<Object>,
  activeSection?: Object,
  isLoading: boolean,
};

/**
 * HOCQuiz
 *
 * @param {*} QuizContainer
 * @returns {Function}
 */
function HOCQuizBase(QuizContainer: any): Function {
  return class extends Component<Props, State> {
    static displayName = HOC_DISPLAY_NAME;

    currrentProgress: number = 0;

    handleQuizSubmit: Function;

    handleAnswer: Function;

    handleNextSelectedQuestion: Function;

    createQuizHandler: Function;

    handleQuestionLoad: Function;

    /**
     * constructor
     *
     * @param {Props} props
     */
    constructor(props: Props) {
      super(props);
      this.state = { isLoading: false };
      this.bindMethods();
      this.props.actionGuiUpdate(GUI_PROPERTIES.QUIZ_PROGRESS, 0);
    }

    /**
     * bindMethods
     */
    bindMethods() {
      this.handleQuizSubmit = this.handleQuizSubmit.bind(this);
      this.createQuizHandler = this.createQuizHandler.bind(this);
      this.handleQuestionLoad = this.handleQuestionLoad.bind(this);
    }

    /**
     * handleQuestionLoad
     * @param {Object} activeSection
     */
    handleQuestionLoad(activeSection: Object = {}) {
      this.updateProgressBar(activeSection);
      this.props.actionGtmQuizQuestionLoaded(activeSection.activeItem);
    }

    /**
     * handleQuizSubmit
     *
     * Handles the answers submission from the quiz
     */
    handleQuizSubmit(quizData) {
      const answers = quizData.reduce((result, page) => {
        const items = page.questions.filter((q) => q.answer).map((q) => q.answer);

        if (items.length) {
          result.push(...items);
        }

        return result;
      }, []);

      const { mutate, visitorId, id: quizId, userId } = this.props;
      this.props.actionGtmQuizCompleted();
      this.setState({ isLoading: true });
      mutate({
        variables: {
          inputQuizResponse: {
            visitorId,
            quizId,
            answers,
            ...(userId ? { userId } : {}),
          },
        },
      })
        .then(this.createQuizHandler)
        .catch(() => {
          this.setState({ isLoading: false });
        });
    }

    /**
     * updateProgressBar
     * @param {Object} activeSection
     */
    updateProgressBar({ activeItemPosition = 0, totalItems = 0 }: Object) {
      const newProgressPercents =
        totalItems && activeItemPosition ? Math.ceil((activeItemPosition / totalItems) * 100) : 0;

      if (this.currrentProgress === newProgressPercents) {
        return null;
      }

      this.currrentProgress = newProgressPercents;
      this.props.actionGuiUpdate(GUI_PROPERTIES.QUIZ_PROGRESS, newProgressPercents);
    }

    /**
     * createQuizHandler
     * @param {Object} response
     * @returns {void}
     */
    createQuizHandler(response: Object): void {
      const { history, locale } = this.props;
      const { visitorIsSubscribed, aclRole } = this.props;
      const { id, profile, answers, created } = response.data.createQuizResponse;
      this.props.actionGtmQuizTaken({ profile, answers, created });

      if (
        !isRoleAllowedForACL(
          CONFIG_APP.acl.resourcePermissions.quiz.results,
          aclRole,
          CONFIG_APP.acl.rolesHierarchy,
        ) &&
        !visitorIsSubscribed
      ) {
        history.push(
          UrlAssembler.byRouteKey('quizRegister', {
            locale,
            search: { quizResponseId: id },
          }),
        );
      } else {
        history.push(UrlAssembler.byRouteKey('quizResults', { locale, search: { id } }));
      }
    }

    /**
     * render
     *
     * @returns {React$Element<div>}
     */
    render() {
      const { visitorStoreCode } = this.props;

      if (!visitorStoreCode) {
        return <IsomorphicRipple isFullScreen isActive />;
      }

      return (
        <Query
          query={LIST_QUIZ_PAGES_QUERY}
          variables={{ quizId: this.props.id, locale: visitorStoreCode }}
          context={{ customOptions: { errorSuppressFromResponse: true } }}
        >
          {({ data, loading, error }) => {
            if (loading) {
              return <IsomorphicRipple isFullScreen isActive={loading} />;
            }

            if (error || !data || (data && !data.listQuizPages)) {
              return null;
            }

            return (
              <QuizContainer
                data={(data && data.listQuizPages && data.listQuizPages.items) || []}
                visitorStoreCode={visitorStoreCode}
                isLoading={this.state.isLoading}
                onQuizSubmit={this.handleQuizSubmit}
                onQuestionLoad={this.handleQuestionLoad}
              />
            );
          }}
        </Query>
      );
    }
  };
}

export const HOCQuiz = (DecoratedComponent: React$Component<*, *>) =>
  withTranslatedRouter(
    withGUIState()(
      connect(
        (state) => ({
          userId: selectUserId(state),
          visitorId: selectVisitorId(state),
          visitorStoreCode: selectStoreCode(state),
          visitorIsSubscribed: !!selectVisitorProperty('email')(state),
          aclRole: selectAclRole(state),
          locale: localeSelector(state),
        }),
        (dispatch) =>
          bindActionCreators(
            {
              actionGtmQuizQuestionLoaded: gtmQuizQuestionLoaded,
              actionGtmQuizCompleted: gtmQuizCompleted,
              actionGtmQuizTaken: gtmQuizTaken,
            },
            dispatch,
          ),
      )(graphql(CREATE_RESPONSE_MUTATION)(HOCQuizBase(DecoratedComponent))),
    ),
  );
