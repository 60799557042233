// @flow

import React, { Component } from 'react';
import type { Node, ComponentType } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import {
  HTML_ID_NOTIFICATIONS_LIST,
  NotificationMessageList,
  NotificationMessage,
} from '@riseart/common';
import { store as CONFIG_STORE } from 'Config';
import { GUI_PROPERTIES } from 'shared_models/Gui';
import {
  removeNotification,
  removeNotificationsByType,
} from 'shared_services/redux/actions/notifications/notifications';
import { guiUpdate } from 'shared_services/redux/actions/application/gui';
import { calcContainerHeight } from 'shared_hocs/messages/utils';

type Props = Object;

/**
 * NotificationMessages
 */
export class NotificationMessages extends Component<Props> {
  currentHeight: number = 0;

  dismissTimeoutId: Array<TimeoutID> = [];

  /**
   * componentDidMount
   */
  componentDidMount() {
    this.calcAndUpdateContainerHeight();
  }

  /**
   * componentDidUpdate
   */
  componentDidUpdate() {
    this.calcAndUpdateContainerHeight();
  }

  /**
   * calcAndUpdateContainerHeight
   */
  calcAndUpdateContainerHeight() {
    const newHeight = calcContainerHeight(
      HTML_ID_NOTIFICATIONS_LIST,
      this.currentHeight,
      (height) => this.props.actionGuiUpdate(GUI_PROPERTIES.NOTIFICATIONS_HEIGHT, height),
    );

    if (newHeight !== undefined && newHeight !== null) {
      this.currentHeight = newHeight;
    }
  }

  /**
   * componentWillUnmount
   */
  componentWillUnmount() {
    this.dismissTimeoutId &&
      this.dismissTimeoutId.forEach((id) => {
        clearTimeout(id);
      });
  }

  handleDismiss: Function;

  /**
   * handleDismiss
   *
   * @param {string} type
   * @returns {Functionn}
   */
  handleDismiss(type: string): Function {
    return () => {
      this.props.actionRemoveNotificationsByType(type);

      this.props.messages.forEach((message) => {
        if (message.type === type && this.dismissTimeoutId[message.id]) {
          clearTimeout(this.dismissTimeoutId[message.id]);
        }
      });
    };
  }

  handleDismissTimeout: Function;

  /**
   * handleDismissTimeout
   *
   * @param {Objeect} message
   */
  handleDismissTimeout(message: Object) {
    clearTimeout(this.dismissTimeoutId[message.id]);

    this.dismissTimeoutId[message.id] = setTimeout(
      () => this.props.actionRemoveNotification(message),
      message.expire * 1000,
    );
  }

  /**
   * bindMessages
   */
  bindMessages() {
    this.handleDismiss = this.handleDismiss.bind(this);
    this.handleDismissTimeout = this.handleDismissTimeout.bind(this);
  }

  /**
   * render
   *
   * @returns {Node}
   */
  render(): Node {
    const { messages, intl } = this.props;
    const uniqueMsgs = messages.reduce((aggregator, item) => {
      if (!aggregator[item.type]) {
        aggregator[item.type] = [];
      }

      if (
        aggregator[item.type] &&
        !aggregator[item.type].some(
          (msg) =>
            msg.detail === item.detail && msg.type === item.type && msg.expire === item.expire,
        )
      ) {
        aggregator[item.type].push(item);
      }

      return aggregator;
    }, {});

    return uniqueMsgs ? (
      <NotificationMessageList>
        {Object.keys(uniqueMsgs).map((type) => {
          const items = uniqueMsgs[type];

          return items && items.length ? (
            <NotificationMessage
              key={type}
              title={intl.formatMessage({
                id: `messages.notifications.titles.${type}`,
              })}
              type={type}
              items={items.map((message) => {
                if (message.expire) {
                  this.handleDismissTimeout(message);
                }
                return intl.formatMessage({ id: message.detail, defaultMessage: message.detail });
              })}
              onClose={this.handleDismiss(type)}
            />
          ) : null;
        })}
      </NotificationMessageList>
    ) : null;
  }
}

export const HOCMessagesNotificationsManager = (DecoratedComponent: ComponentType<*>): Object =>
  connect<*, *, *, *, *, *>(
    (state) => ({ messages: state[CONFIG_STORE.keys.notifications] }),
    (dispatch) => ({
      actionRemoveNotification: (message) => dispatch(removeNotification(message)),
      actionRemoveNotificationsByType: (type) => dispatch(removeNotificationsByType(type)),
      actionGuiUpdate: (key, height) => dispatch(guiUpdate(key, height)),
    }),
  )(injectIntl(DecoratedComponent));
