// @flow

import React from 'react';
import type { Node } from 'react';
import { payment as CONFIG_PAYMENT } from 'Config';
import { cart as ENUM_CART } from 'Enum';
import type { PaymentMethod, PaymentMethodData } from 'shared_hocs/cart/payments/IPaymentMethod';
import { PaymentBase } from 'shared_hocs/cart/payments/Base';
import {
  SUPPORTED_CARDS,
  DatacashForm,
} from 'shared_components/checkout/sections/payment/types/Datacash';
import { DatacashProvider } from 'shared_components/checkout/sections/payment/types/DatacashProvider';
import { DatacashPaymentDescription } from 'shared_components/checkout/sections/payment/description/Datacash';
import { parseCardDate } from 'shared_services/riseart/utils/Cart';
import { PaymentDetailDatacash } from 'shared_components/cart/sections/payment/Datacash';

/**
 * DatacashAdapter
 */
export class DatacashAdapter extends PaymentBase implements PaymentMethod {
  props: Object;

  state: Object;

  handleError: Function;

  successCallback: Function;

  /**
   * settings
   */
  static settings: Object = {
    internalValidation: true,
    rent: { canSaveCard: true, canUseSavedCards: true },
    buy: { canSaveCard: true, canUseSavedCards: true },
  };

  /**
   * getPaymentMethodName
   *
   * @returns {string}
   */
  static getPaymentMethodName(): string {
    return ENUM_CART.payment.method.METHOD_DATACASH;
  }

  /**
   * isEnabled
   *
   * @returns {boolean}
   */
  static isEnabled(): boolean {
    return CONFIG_PAYMENT.datacash.enabled;
  }

  /**
   * canSaveCard
   *
   * @returns {boolean}
   */
  canSaveCard(): boolean {
    const settings = DatacashAdapter.settings[this.props.cartType];

    return !!(settings && settings.canSaveCard);
  }

  /**
   * canUseSavedPayments
   */
  static canUseSavedPayments(cartType: 'buy' | 'rent'): boolean {
    const settings = DatacashAdapter.settings[cartType];

    return settings && settings.canUseSavedCards;
  }

  /**
   * getSupportedCards
   *
   * @param {boolean} showAsArray
   * @returns {Object}
   */
  static getSupportedCards(showAsArray: boolean = false): Object {
    return showAsArray
      ? Object.keys(SUPPORTED_CARDS).reduce(
          (acc, cardKey) => [...acc, SUPPORTED_CARDS[cardKey]],
          [],
        )
      : SUPPORTED_CARDS;
  }

  /**
   * displaySupportedPayments
   *
   * @returns {Array<Object>}
   */
  static displaySupportedPayments(): Array<Object> {
    return DatacashAdapter.getSupportedCards(true);
  }

  /**
   * hasInternalValidation
   */
  static hasInternalValidation(): boolean {
    return DatacashAdapter.settings.internalValidation;
  }

  /**
   * renderBillingInfo
   *
   * @param {Object} checkoutProps
   * @param {Object} billingData
   * @returns {Object}
   */
  static renderBillingInfo(checkoutProps: Object, billingData: Object): Object {
    return <DatacashPaymentDescription checkoutProps={checkoutProps} billingData={billingData} />;
  }

  /**
   * getPaymentProvider
   *
   * @returns {Object}
   */
  static getPaymentProvider(): Object {
    return DatacashProvider;
  }

  /**
   * getPaymentForm
   */
  static getPaymentForm(): Object {
    return DatacashForm;
  }

  /**
   * enhanceCardData
   *
   * @param {Object} data
   * @returns {Object}
   */
  static enhanceCardData(data: Object): Object {
    const cardExpirationDate = parseCardDate(data.cardValidThru);
    const cardStartDate = data.cardStartDate ? parseCardDate(data.cardStartDate) : null;

    return {
      cardEndMonth: cardExpirationDate.month,
      cardEndYear: cardExpirationDate.year,
      ...(cardStartDate
        ? {
            cardStartMonth: cardStartDate.month,
            cardStartYear: cardStartDate.year,
          }
        : null),
    };
  }

  /**
   * delayReadOrder
   *
   * @returns {number}
   */
  static delayReadOrder(): number {
    return 0;
  }

  /**
   * renderPaymentInformation
   *
   * @param {Object} data
   * @returns {Node}
   */
  static renderPaymentInformation(data: Object): Node {
    return <PaymentDetailDatacash order={data} />;
  }

  /**
   * constructor
   *
   * @param {Object} props
   * @param {Object} state
   * @param {Function} handleError
   */
  constructor(props: Object, state: Object, handleError: Function, successCallback: Function) {
    super();
    this.props = props;
    this.state = state;
    this.handleError = handleError;
    this.successCallback = successCallback;
  }

  /**
   * hydrate
   *
   * @param {Object} data
   * @return {Object}
   */
  hydrate(data: Object): Object {
    Object.keys(data).forEach((key: string) => {
      // $FlowFixMe
      this[key] = data[key];
    });

    return this;
  }

  /**
   * getOrderVariables
   *
   * @param {Props} props
   * @param {State} state
   * @returns {Object}
   */
  getOrderVariables(props: Object, { billing }: Object): Object {
    return {
      cardType: billing.data.cardType,
      cardNumber: billing.data.cardNumber,
      cardOwner: billing.data.cardOwner,
      cardEndMonth: billing.data.cardEndMonth,
      cardEndYear: billing.data.cardEndYear,
      cardSecurity: billing.data.cardSecurity,
      cardStartMonth: billing.data.cardStartMonth,
      cardStartYear: billing.data.cardStartYear,
      cardIssue: billing.data.cardIssue,
    };
  }

  /**
   * placeOrder
   *
   * @param {Function} successCallback
   * @returns {Promise<any>}
   */
  async placeOrder(): Promise<any> {
    const { cart, isRent, marketingCampaignId } = this.props;
    const { shipping, billing, legalAgreementId } = this.state;

    return this.props.onCreateOrder(
      {
        inputCheckout: {
          savePayment: billing.data.savePayment || false,
          saveShippingAddress: shipping.data.saveShippingAddress || false,
          // $FlowFixMe
          ...(isRent ? { legalAgreementId } : null),
          ...(marketingCampaignId ? { marketingCampaignId } : null),
        },
        inputPayment: {
          method: ENUM_CART.payment.method.METHOD_DATACASH,
          ...(!billing.data.userPaymentId
            ? this.getOrderVariables(this.props, this.state)
            : { userPaymentId: billing.data.userPaymentId }),
          totalAmount: cart.totalAmount,
        },
      },
      this.successCallback,
    );
  }

  /**
   * getPaymentMethodData
   *
   * @returns {Promise<PaymentMethodData | null>}
   */
  async getPaymentMethodData(): Promise<PaymentMethodData | null> {
    return new Promise((resolve) =>
      resolve({
        status: DatacashAdapter.PAYMENT_STATUS.COMPLETE,
        error: null,
      }),
    );
  }

  /**
   * shouldCancelOrder
   *
   * @returns {Promise<boolean>}
   */
  async shouldCancelOrder(): Promise<boolean> {
    return new Promise((resolve) => resolve(false));
  }
}
