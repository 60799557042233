// @flow

import React, { Component } from 'react';

// Constants
const HOC_DISPLAY_NAME = 'HOCSort';

type Props = {
  label: string,
  urlAssembler: Function,
  options: Object,
  defaultOption?: string,
  history: Object,
  currentValue: ?string,
  formatMessage: Function,
};

/**
 * HOCSort
 *
 * @param {React$Component<*, *>} SortComponent
 */
export function HOCSort(SortComponent: React$Component<*, *>) {
  return class extends Component<Props> {
    static displayName = HOC_DISPLAY_NAME;

    /**
     * constructor
     *
     * @param props
     */
    constructor(props: Props) {
      super(props);

      this.bindMethods();
    }

    /**
     * bindMethods
     */
    bindMethods() {
      this.sortHandler = this.sortHandler.bind(this);
    }

    /**
     * sortHandler
     *
     * @param {string} selectedSort
     */
    sortHandler: Function;

    sortHandler(selectedSort: string) {
      const { urlAssembler, options, defaultOption, history } = this.props;
      const defaultValue = defaultOption || options.RANKING_DEFAULT;

      history.push(
        urlAssembler(
          {
            search:
              selectedSort !== defaultValue ? `?sort=${encodeURIComponent(selectedSort)}` : '',
          },
          selectedSort !== defaultValue ? selectedSort : null,
        ),
      );
    }

    /**
     * getSortList
     *
     * @returns {Array<{ value: string, selected: boolean, label: string }>}
     */
    getSortList(): Array<{ value: string, selected: boolean, label: string }> {
      const { options, currentValue, formatMessage } = this.props;

      return Object.keys(options).map((key) => {
        const value = options[key];

        return {
          value,
          selected: currentValue === value,
          label: formatMessage({ id: `options.sort.art.${value}` }),
        };
      });
    }

    /**
     * render
     */
    render() {
      return (
        <SortComponent
          label={this.props.label}
          options={this.getSortList()}
          onChange={this.sortHandler}
        />
      );
    }
  };
}
