// @flow
import {
  heroHeightSmall,
  heroHeightMedium,
  heroHeightLarge,
  topMarginSmall,
  topMarginLarge,
  fallbackColor,
} from 'shared_components/cms/modules/hero/Carousel.less';
import { defaultFallbackColor, darkFallbackColor } from 'shared_hocs/cms/views/fallbackStyles.less';

/**
 * CMS_FALLBACK_CONFIGS
 *
 * Preloader content fallback configuration,
 * based on key generated from router page key and placholder (${ROUTER_CONFIG.key}.${placeholder})
 */
export const CMS_FALLBACK_CONFIGS = {
  'home.cmsHomeCarousel': {
    sm: {
      height: heroHeightSmall,
      backgroundColor: fallbackColor,
      marginTop: topMarginSmall,
    },
    md: {
      height: heroHeightMedium,
      backgroundColor: fallbackColor,
      marginTop: topMarginSmall,
    },
    lg: {
      height: heroHeightLarge,
      backgroundColor: fallbackColor,
      marginTop: topMarginLarge,
    },
  },
  'home.cmsHomeFilters': { sm: { height: 513 }, md: { height: 288 }, lg: { height: 368 } },
  'home.cmsHomeArts1': { common: { height: 480 } },
  'home.cmsHomeArts2': { common: { height: 480 } },
  'home.cmsHomeBanner1': { common: { height: 500, backgroundColor: darkFallbackColor } },
  'home.cmsHomeArtists': { sm: { height: 665 }, md: { height: 513 }, lg: { height: 584 } },
  'home.cmsHomeArticles': {
    sm: {
      height: 656,
      backgroundColor: defaultFallbackColor,
    },
    md: {
      height: 722,
      backgroundColor: defaultFallbackColor,
    },
    lg: {
      height: 826,
      backgroundColor: defaultFallbackColor,
    },
  },
  'home.cmsHomeCollections': { sm: { height: 772 }, md: { height: 500 }, lg: { height: 500 } },
  'home.cmsHomeBanner2': { common: { height: 500, backgroundColor: darkFallbackColor } },
  'home.cmsHomePress': { common: { height: 175 } },
  'home.cmsHomeReasons': {
    sm: {
      height: 750,
      backgroundColor: darkFallbackColor,
    },
    md: {
      height: 396,
      backgroundColor: darkFallbackColor,
    },
    lg: {
      height: 355,
      backgroundColor: darkFallbackColor,
    },
  },
  'quizStart.cmsQuizStartContent': { common: { height: 430 } },
  'quizStart.cmsQuizStartImage': { common: { height: 430 } },
};
