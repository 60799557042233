// @flow

import React, { Component } from 'react';
import type { Node } from 'react';

type Props = {
  children: Node,
  resizeHandlerTimeout?: number,
};

type State = { width: number };

/**
 * StretchToRight
 */
export class StretchToRight extends Component<Props, State> {
  static defaultProps: Object = { resizeHandlerTimeout: 1000 };

  wrapperRef: Object = null;

  // eslint-disable-next-line
  timeoutId: TimeoutID;

  /**
   * constructor
   *
   * @param {Props} props
   */
  constructor(props: Props) {
    super(props);

    this.state = { width: 0 };

    this.wrapperRef = React.createRef();

    this.calcContainerWidth = this.calcContainerWidth.bind(this);
  }

  /**
   * componentDidMount
   */
  componentDidMount() {
    this.calcContainerWidth();
    window.addEventListener('resize', this.handleResize);
  }

  /**
   * componentWillUnmount
   */
  componentWillUnmount() {
    clearTimeout(this.timeoutId);
    window.removeEventListener('resize', this.handleResize);
  }

  /**
   * calcContainerWidth
   *
   * @returns {number}
   */
  calcContainerWidth: Function;

  calcContainerWidth(): number {
    const { right, width } = this.wrapperRef.current.getBoundingClientRect();
    const calcWidth = width + window.innerWidth - right;

    this.setState({ width: calcWidth });

    return calcWidth;
  }

  /**
   * handleScroll
   */
  handleResize = () => {
    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(this.calcContainerWidth, this.props.resizeHandlerTimeout);
  };

  /**
   * render
   */
  render() {
    return (
      <div
        ref={this.wrapperRef}
        style={{ width: this.state.width ? `${this.state.width}px` : 'auto' }}
      >
        {this.props.children}
      </div>
    );
  }
}
