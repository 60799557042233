// @flow

import queryString from 'query-string';
import get from 'lodash/get';
import pick from 'lodash/pick';
import React, { Component } from 'react';
import type { Node, ComponentType } from 'react';
import { connect } from 'react-redux';
import { withTranslatedRouter } from 'shared_data/providers/url/withTranslatedRouter';
import { store as CONFIG_STORE, cookies as COOKIES_CONFIG } from 'Config';
import { Cookies } from 'shared_services/riseart/utils/Cookies/Cookies';

export type authParamsType = {
  forward?: string,
  quizResponseId?: string | number,
  marketingCampaignId?: string | number,
  referrerId?: string,
};

type Props = {
  marketingCampaignId: ?string,
  pickedParams?: Array<string>,
  location: Object,
  children: Node,
  currentLocale: Object,
  pageConfig?: Object,
  routeConfig?: Object,
  skipRedirect?: Boolean,
};

type State = {
  queryString: string,
  params: Object,
  referrerId: ?string,
};

const HOC_DISPLAY_NAME = 'HOCAuthParams';

/**
 * HOC
 *
 * @param {Component<*, *>} DecoratedComponent
 */
const HOC = (DecoratedComponent: ComponentType<*>) =>
  class extends Component<Props, State> {
    static displayName = HOC_DISPLAY_NAME;

    static defaultProps: Object = {
      pickedParams: null,
    };

    /**
     * constructor
     *
     * @param {Props} props
     */
    constructor(props: Props) {
      super(props);

      const {
        location: { search },
        pickedParams,
      } = this.props;
      const qsParams = queryString.parse(search);
      this.state = {
        queryString: search,
        params: pickedParams ? pick(qsParams, pickedParams) : qsParams,
        referrerId: Cookies.get(COOKIES_CONFIG.referrerSession.name) || null,
      };
    }

    /**
     * getDerivedStateFromProps
     *
     * @param {Props} props
     * @param {State} state
     * @returns {State} new derived dstate from props
     */
    static getDerivedStateFromProps({ pickedParams, location: { search } }, state) {
      // do not update state if query string does not change
      // update the condition if in future state has to load data from other sources
      if (state.queryString === search) {
        return null;
      }

      const qsParams = queryString.parse(search);

      return {
        params: pickedParams ? pick(qsParams, pickedParams) : qsParams,
        referrerId: Cookies.get(COOKIES_CONFIG.referrerSession.name) || null,
      };
    }

    /**
     * render
     */
    render() {
      const {
        skipRedirect,
        marketingCampaignId,
        currentLocale,
        pageConfig,
        routeConfig,
        children,
        ...restProps
      } = this.props;
      const { params, referrerId } = this.state;

      return (
        <DecoratedComponent
          {...restProps}
          authParams={{
            ...params,
            ...(marketingCampaignId ? { marketingCampaignId } : null),
            ...(referrerId ? { referrerId } : null),
          }}
          {...(skipRedirect ? { skipRedirect } : null)}
          currentLocale={currentLocale}
          pageConfig={pageConfig || routeConfig}
        >
          {children || null}
        </DecoratedComponent>
      );
    }
  };

/**
 * mapStateToProps
 *
 * @param {Object} state Redux store state
 */
const mapStateToProps: Function = (state: Object) => ({
  marketingCampaignId: get(state[CONFIG_STORE.keys.marketing], 'data.id', null),
});

/**
 * HOCAuthParams
 * @param {Component<*, *>} DecoratedComponent
 */
export const HOCAuthParams = (DecoratedComponent: ComponentType<*>): ComponentType<*> =>
  connect<*, *, *, *, *, *>(mapStateToProps)(withTranslatedRouter(HOC(DecoratedComponent)));
