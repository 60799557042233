// @flow

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectVisitorId } from 'shared_services/redux/selectors/visitor';
import { selectStoreCode } from 'shared_services/redux/selectors/storeCode';
import { selectAclRole, selectAuthShippingCountryCode } from 'shared_services/redux/selectors/auth';
import { filterApplicationMessages } from 'shared_hocs/messages/application/Manager';

export const DEVICE = {
  MOBILE: 'mobile',
  TABLET: 'tablet',
  DESKTOP: 'desktop',
};

type InputData = {
  location: Object,
  sort: 'asc' | 'desc',
  messageLocation: String,
  pagination: Object,
  device?: 'mobile' | 'tablet' | 'desktop' | null,
};

/**
 * useApplicationMessages
 *
 * @param {Props} props
 * @returns {Object}
 */
export function useApplicationMessages({
  location,
  sort = 'asc',
  messageLocation,
  pagination,
  device,
}: InputData): Object {
  const [messages, setMessages] = useState([]);
  const visitorId = useSelector(selectVisitorId);
  const storeCode = useSelector(selectStoreCode);
  const shippingCountryCode = useSelector(selectAuthShippingCountryCode);
  const aclRole = useSelector(selectAclRole);
  const currentDevice = device || DEVICE.DESKTOP;

  useEffect(() => {
    const auth = { visitorId, storeCode, shippingCountryCode, aclRole };

    if (!auth.visitorId) {
      return;
    }

    setMessages(
      filterApplicationMessages({
        messageLocation,
        auth,
        sort,
        location,
        pagination,
        device: currentDevice,
      }),
    );
  }, [
    visitorId,
    storeCode,
    shippingCountryCode,
    aclRole,
    messageLocation,
    sort,
    location,
    pagination,
    currentDevice,
  ]);

  function getFlatMessages() {
    return messages.map(
      ({ show, dismissed, details: { options, conditions, ...restDetails } }) => ({
        ...restDetails,
        ...options,
        show,
        dismissed,
      }),
    );
  }

  return { messages, getFlatMessages };
}
