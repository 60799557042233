// @flow

import React, { Component } from 'react';
import { store as STORE_CONFIG } from 'Config';
import { filter as FILTER_ENUM } from 'Enum';
import { WithStore } from 'shared_hocs/connect/withStore';

const HOC_DISPLAY_NAME = 'withFilterState';

// domain to store key mapper used for selecting proper redux store key based on filter domain
export const filterDomainToStoreKey = {
  [FILTER_ENUM.domain.ART]: STORE_CONFIG.keys.filterArt,
  [FILTER_ENUM.domain.COLLECTION]: STORE_CONFIG.keys.filterCollection,
  [FILTER_ENUM.domain.COLLECTION_ART]: STORE_CONFIG.keys.filterCollectionArt,
  [FILTER_ENUM.domain.ARTIST]: STORE_CONFIG.keys.filterArtist,
  [FILTER_ENUM.domain.ARTIST_ART]: STORE_CONFIG.keys.filterArtistArt,
  [FILTER_ENUM.domain.FAVORITES_ART]: STORE_CONFIG.keys.filterFavoritesArt,
  [FILTER_ENUM.domain.FAVORITES_ARTIST]: STORE_CONFIG.keys.filterFavoritesArtist,
};

/**
 * defaultMapStateToProp
 * @param {Object} state
 * @return {Object}
 */
const defaultMapStateToProp = (filterState: Object): Object => ({
  filterState,
});

/**
 * propsMapper
 * @param {Function} stateMapper
 * @returns {Function}
 */
const propsMapper =
  (domain: string) =>
  (stateMapper: Function = defaultMapStateToProp) =>
  (state: Object): Object =>
    stateMapper(state[filterDomainToStoreKey[domain]]);

/**
 * withFilterState
 * @param {Class<Component<*, *>>} DecoratedComponent
 * @param {Function} stateMapper
 * @param {Function | Object} mapDispatchToProps
 */
export function withFilterState(
  domain: string,
  DecoratedComponent: Class<Component<*, *>>,
  stateMapper?: Function,
  mapDispatchToProps?: Function | Object,
) {
  return class extends Component<*, *> {
    static displayName = HOC_DISPLAY_NAME;

    /**
     * render
     * @returns {React$Element}
     */
    render() {
      return (
        <WithStore
          mapStateToProps={propsMapper(domain)(stateMapper)}
          {...(mapDispatchToProps ? { mapDispatchToProps } : {})}
        >
          {({ mapStateToProps, mapDispatchToProps, ...restProps }) => (
            <DecoratedComponent {...this.props} {...restProps} />
          )}
        </WithStore>
      );
    }
  };
}
