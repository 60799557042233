// @flow

import { Component } from 'react';
import type { ComponentType } from 'react';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { store as CONFIG_STORE } from 'Config';

/**
 * WithFilterState
 */
class FavoritesComponent extends Component<*, *> {
  render() {
    const { children, isFavorited, favoriteId, list } = this.props;

    return children({ isFavorited, favoriteId, ...(list ? { list } : {}) });
  }
}

export const HOCFavorites = (DecoratedComponent: ComponentType<*>, mapDispatchToProps: ?Function) =>
  connect<*, *, *, *, *, *>((state, { artId, includeList }) => {
    const list = state[CONFIG_STORE.keys.favorites].data;
    const favoriteItem = list[parseInt(artId, 10)];

    return {
      favoriteId: get(favoriteItem, 'id'),
      isFavorited: !!favoriteItem,
      ...(includeList ? { list } : {}),
    };
  }, mapDispatchToProps)(DecoratedComponent);

export const WithFavorites = HOCFavorites(FavoritesComponent);
