// @flow

import React from 'react';
import type { Node } from 'react';
import { cart as ENUM_CART } from 'Enum';
import type { PaymentMethod } from 'shared_hocs/cart/payments/IPaymentMethod';
import { PaymentBase } from 'shared_hocs/cart/payments/Base';
import { CustomerCreditProvider } from 'shared_components/checkout/sections/payment/types/CustomerCredit';
import { PaymentDetailCustomerCredit } from 'shared_components/cart/sections/payment/CustomerCredit';

/**
 * CustomerCreditAdapter
 */
export class CustomerCreditAdapter extends PaymentBase implements PaymentMethod {
  props: Object;

  state: Object;

  /**
   * getPaymentMethodName
   *
   * @returns {string}
   */
  static getPaymentMethodName(): string {
    return ENUM_CART.payment.method.METHOD_CREDIT;
  }

  /**
   * isEnabled
   *
   * @returns {boolean}
   */
  static isEnabled(): boolean {
    return true;
  }

  /**
   * renderPaymentInformation
   *
   * @param {Object} data
   * @returns {Node}
   */
  static renderPaymentInformation(data: Object): Node {
    return <PaymentDetailCustomerCredit order={data} />;
  }

  /**
   * constructor
   *
   * @param {Object} props
   * @param {Object} state
   * @param {Function} handleError
   */
  constructor(props: Object, state: Object) {
    super();
    this.props = props;
    this.state = state;
  }

  /**
   * hydrate
   *
   * @param {Object} data
   * @return {Object}
   */
  hydrate(data: Object): Object {
    Object.keys(data).forEach((key: string) => {
      // $FlowFixMe
      this[key] = data[key];
    });

    return this;
  }

  /**
   * getPaymentProvider
   *
   * @returns {Object}
   */
  static getPaymentProvider(): Object {
    return CustomerCreditProvider;
  }
}
