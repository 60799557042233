// @flow

/**
 * calcContainerHeight
 *
 * @param {string} elmId
 * @param {number} currentHeight
 * @param {function} onHeightChange
 * @returns {?number}
 */
export function calcContainerHeight(
  elmId: string,
  currentHeight: number,
  onHeightChange: function,
): ?number {
  const elm = document.getElementById(elmId);
  const height = elm ? elm.offsetHeight : 0;

  // Check if current and new height are different
  if (currentHeight !== height) {
    onHeightChange(height);

    return height;
  }
}
