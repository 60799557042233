// @flow

import { withGUIState } from 'shared_hocs/gui/withGuiState.js';
import { GUI_PROPERTIES } from 'shared_models/Gui';

type Props = {
  headerSelector: string,
  applicationsHeight: number,
  notificationsHeight: number,
  children: Function,
};

/**
 * OffsetHeight
 * Component that collects the applicationsHeight and notificationsHeight from GUI redux state,
 * and the headerHeight from DOM header element.
 *
 * Based on the application and notification messages and header height it caclulates:
 *     totalMessagesHeight = applicationsHeight + notificationsHeight
 *     totalHeight = applicationsHeight + notificationsHeight + headerHeight
 * @param {Props} props
 */
const OffsetHeight: Function = ({
  headerSelector = '.ra-header',
  applicationsHeight,
  notificationsHeight,
  children,
}: Props) => {
  const headerElm = typeof document !== 'undefined' && document.querySelector(headerSelector);

  const headerHeight = headerElm ? headerElm.offsetHeight : 0;

  return children({
    applicationsHeight,
    notificationsHeight,
    headerHeight,
    totalMessagesHeight: applicationsHeight + notificationsHeight,
    totalHeight: applicationsHeight + notificationsHeight + headerHeight,
  });
};

const mapStateToProps: Function = (guiState) => ({
  applicationsHeight: guiState[GUI_PROPERTIES.APPLICATION_MSG_HEIGHT] || 0,
  notificationsHeight: guiState[GUI_PROPERTIES.NOTIFICATIONS_HEIGHT] || 0,
});

export const WithOffsetHeight = withGUIState(mapStateToProps)(OffsetHeight);
