// @flow

import React, { Component } from 'react';
import type { ComponentType } from 'react';
import { connect } from 'react-redux';
import { Mutation } from '@apollo/client/react/components';
import { injectIntl } from 'react-intl';
import { Button } from '@riseart/common';
import { WishlistIcon } from '@riseart/icons';
import { TileList, TileListItem } from '@riseart/layout';
import { cart as ENUM_CART } from 'Enum';
import { selectUnitSystem } from 'shared_services/redux/selectors/unitSystem';
import { getGraphqlOperationName, cacheUpdateHandler } from 'shared_services/apollo/helpers';
import { cartItemsActionFactory } from 'shared_services/redux/actions/cart/cart';
import { mapCartDataToProps, getStaticCartItemProps } from 'shared_services/riseart/utils/Cart';
import { WithLocale } from 'shared_hocs/common/WithLocale';
import { FavoritesToggleMutation } from 'shared_data/providers/queries/FavoritesToggleMutation';

// GraphQL Mutations and Queries
import READ_CART_QUERY from 'shared_data/queries/cart/read.graphql';
import UPDATE_ITEM_MUTATION from 'shared_data/queries/cart/item/update.graphql';
import DELETE_ITEM_MUTATION from 'shared_data/queries/cart/item/delete.graphql';
import FILTER_FAVORITES_QUERY from 'shared_data/queries/visitor/filterFavorites.graphql';

const GQL_MUTATION_NAMES = {
  update: getGraphqlOperationName(UPDATE_ITEM_MUTATION),
  delete: getGraphqlOperationName(DELETE_ITEM_MUTATION),
};

const HOC_DISPLAY_NAME = 'HOCCartItems';

/**
 * HOCItems
 *
 * @param {Component<*>} DecoratedComponent
 */
const HOCItems = (DecoratedComponent: ComponentType<*>) =>
  class extends Component<*> {
    static displayName = HOC_DISPLAY_NAME;

    static defaultProps = {
      lazyloadImages: true,
    };

    staticItemProps: Object;

    /**
     * constructor
     *
     * @param {Object} props
     */
    constructor(props: Object) {
      super(props);

      this.bindMethods();
      this.staticItemProps = getStaticCartItemProps(
        this.props.cartType === ENUM_CART.type.TYPE_RENT,
      );
    }

    /**
     * bindMethods
     */
    bindMethods() {
      this.handleQuantityChange = this.handleQuantityChange.bind(this);
      this.handleFrameChange = this.handleFrameChange.bind(this);
      this.handleInsuranceChange = this.handleInsuranceChange.bind(this);
      this.handleDelete = this.handleDelete.bind(this);
      this.handleLoading = this.handleLoading.bind(this);
      this.handleError = this.handleError.bind(this);
    }

    /**
     * handleQuantityChange
     *
     * @param {Object} mutations
     * @param {number} id
     * @param {key} key
     */
    handleQuantityChange: Function;

    handleQuantityChange({ updateCartItem }: Object, id: number, key: string) {
      const { cartType, storeCode, visitorId, items } = this.props;

      return (qty: number) => {
        this.handleLoading(true);
        updateCartItem({
          variables: { id, cartId: cartType, store: storeCode, visitorId, key, qty },
          update: cacheUpdateHandler(READ_CART_QUERY, GQL_MUTATION_NAMES.update, {
            cartId: cartType,
            store: storeCode,
            visitorId,
          }),
        })
          .then((response) => {
            this.responseHandler(response && response.data && response.data.updateCartItem, items);
          })
          .catch(this.handleError);
      };
    }

    /**
     * handleFrameChange
     *
     * @param {Object} mutations
     * @param {number} id
     * @param {key} key
     * @param {number} qty
     * @param {number} optionsId
     */
    handleFrameChange: Function;

    handleFrameChange(
      { updateCartItem }: Object,
      id: number,
      key: string,
      qty: number,
      optionsId: number,
    ) {
      const { cartType, storeCode, visitorId, items } = this.props;

      return (frame: Object) => {
        this.handleLoading(true);
        updateCartItem({
          variables: {
            id,
            cartId: cartType,
            store: storeCode,
            visitorId,
            key,
            qty,
            options: frame.id ? [{ id: optionsId, values: [frame.id] }] : [],
          },
          update: cacheUpdateHandler(READ_CART_QUERY, GQL_MUTATION_NAMES.update, {
            cartId: cartType,
            store: storeCode,
            visitorId,
          }),
        })
          .then((response) => {
            this.responseHandler(response && response.data && response.data.updateCartItem, items);
          })
          .catch(this.handleError);
      };
    }

    /**
     * handleInsuranceChange
     *
     * @param {Object} mutations
     * @param {number} id
     * @param {key} key
     * @param {number} qty
     * @param {number} optionsId
     */
    handleInsuranceChange: Function;

    handleInsuranceChange(
      { updateCartItem }: Object,
      id: number,
      key: string,
      qty: number,
      insurance: ?Object,
    ) {
      const { cartType, storeCode, visitorId, items } = this.props;

      return () => {
        this.handleLoading(true);
        let options = [];

        if (insurance && insurance.id) {
          options = [
            {
              id: insurance.id,
              values: !insurance.value.selected ? [insurance.value.id] : [],
            },
          ];
        }

        updateCartItem({
          variables: {
            id,
            cartId: cartType,
            store: storeCode,
            visitorId,
            key,
            qty,
            options,
          },
          update: cacheUpdateHandler(READ_CART_QUERY, GQL_MUTATION_NAMES.update, {
            cartId: cartType,
            store: storeCode,
            visitorId,
          }),
        })
          .then((response) => {
            this.responseHandler(response && response.data && response.data.updateCartItem, items);
          })
          .catch(this.handleError);
      };
    }

    /**
     * handleDelete
     *
     * @param {Object} mutations
     * @param {number} id
     * @param {key} key
     */
    handleDelete: Function;

    handleDelete({ deleteCartItem }: Object, id: number, key: string) {
      const { cartType, storeCode: store, visitorId, items } = this.props;

      return () => {
        this.handleLoading(true);
        deleteCartItem({
          variables: { cartId: cartType, store, visitorId, key, id },
          update: cacheUpdateHandler(READ_CART_QUERY, GQL_MUTATION_NAMES.delete, {
            cartId: cartType,
            store,
            visitorId,
          }),
        })
          .then((response) => {
            this.responseHandler(response && response.data && response.data.deleteCartItem, items);
          })
          .catch(this.handleError);
      };
    }

    /**
     * handleWishlist
     *
     * @param {Object} mutations
     * @param {number} id
     * @param {key} key
     * @param {number} artId
     */
    handleWishlist: Function;

    handleWishlist(mutations: Object, id: number, key: string, artId: number) {
      const { visitorId } = this.props;

      return ({ isFavorited, addFavorite }: Object) => {
        this.handleLoading(true);

        if (isFavorited) {
          this.handleDelete(mutations, id, key)();
        } else {
          addFavorite(visitorId, artId)
            .then(this.handleDelete(mutations, id, key))
            .catch(this.handleError);
        }
      };
    }

    /**
     * handleError
     */
    handleError: Function;

    handleError(error: Object) {
      this.props.actionJSErrorAdd(error);
      this.handleLoading(false);
    }

    /**
     * handleLoading
     *
     * @param {bolean} isLoading
     */
    handleLoading: Function;

    handleLoading(isLoading: boolean) {
      if (typeof this.props.onLoading === 'function') {
        this.props.onLoading(isLoading);
      }
    }

    /**
     * responseHandler
     *
     * @param {Object} responseData
     * @param {Array<Object>} prevItems?
     */
    responseHandler(responseData: Object, prevItems?: Array<Object>) {
      if (
        responseData.cart &&
        responseData.cart.messages &&
        responseData.cart.messages.length > 0
      ) {
        this.props.actionErrorAdd(responseData.cart.messages);
      }

      if (responseData && responseData.actions && responseData.actions.length > 0) {
        this.props.actionUpdateCartItem(responseData, prevItems);
      }

      this.handleLoading(false);
    }

    /**
     * render
     */
    render() {
      const { storeCode, cartType, items, intl, unitSystem, displayStyle = 'main' } = this.props;
      const isCartRental = cartType === ENUM_CART.type.TYPE_RENT;

      return (
        <TileList>
          <Mutation mutation={UPDATE_ITEM_MUTATION}>
            {(updateCartItem) => (
              <Mutation mutation={DELETE_ITEM_MUTATION}>
                {(deleteCartItem) => (
                  <WithLocale>
                    {(locale) =>
                      items &&
                      items.map((item) => {
                        const mappedProps = mapCartDataToProps(
                          {
                            item,
                            storeCode,
                            isCartRental,
                            unitSystem,
                          },
                          locale,
                          this.props.lazyloadImages,
                          intl.formatMessage,
                        );
                        const mutations = { updateCartItem, deleteCartItem };

                        return (
                          <TileListItem key={item.id}>
                            <DecoratedComponent
                              displayStyle={displayStyle}
                              {...this.staticItemProps}
                              {...mappedProps}
                              onCountUpdate={this.handleQuantityChange(
                                mutations,
                                item.id,
                                item.key,
                              )}
                              onFrameSelect={this.handleFrameChange(
                                mutations,
                                item.id,
                                item.key,
                                item.qty,
                                mappedProps.frameOptions && mappedProps.frameOptions.frameOptionsId,
                              )}
                              onInsuranceChange={this.handleInsuranceChange(
                                mutations,
                                item.id,
                                item.key,
                                item.qty,
                                mappedProps.insurance,
                              )}
                              onDelete={this.handleDelete(mutations, item.id, item.key)}
                              favoriteButton={
                                // Show favorites icon only for main displayStyle
                                !this.props.displayStyle || this.props.displayStyle === 'main' ? (
                                  <FavoritesToggleMutation
                                    artId={item.artId}
                                    refetchQueries={
                                      this.props.cartType === ENUM_CART.type.TYPE_BUY
                                        ? [
                                            {
                                              query: FILTER_FAVORITES_QUERY,
                                              variables: {
                                                visitorId: this.props.visitorId,
                                                inputArtFilter: {
                                                  items: 28,
                                                  store: this.props.storeCode,
                                                  page: 1,
                                                },
                                              },
                                            },
                                          ]
                                        : null
                                    }
                                    onClick={this.handleWishlist(
                                      mutations,
                                      item.id,
                                      item.key,
                                      item.artId,
                                    )}
                                  >
                                    {({ onClick }) => (
                                      <Button
                                        icon={<WishlistIcon />}
                                        type="link"
                                        linkType="light"
                                        onClick={onClick}
                                      />
                                    )}
                                  </FavoritesToggleMutation>
                                ) : null
                              }
                            />
                          </TileListItem>
                        );
                      })
                    }
                  </WithLocale>
                )}
              </Mutation>
            )}
          </Mutation>
        </TileList>
      );
    }
  };

/**
 * mapStateToProps
 *
 * @param {Object} state
 * @returns {Object} mapped state to props
 */
const mapStateToProps = (state: Object): Object => {
  return { unitSystem: selectUnitSystem(state).unitSystem };
};

/**
 * mapDispatchToProps
 *
 * @param {Function} dispatch
 * @returns {Object}
 */
const mapDispatchToProps = (dispatch: Function): Object => ({
  actionUpdateCartItem: cartItemsActionFactory(dispatch),
});

/**
 * HOCCartItems
 *
 * @param {ComponentType<*>} DecoratedComponent
 */
export const HOCCartItems = (DecoratedComponent: ComponentType<*>) =>
  connect<*, *, *, *, *, *>(
    mapStateToProps,
    mapDispatchToProps,
  )(injectIntl(HOCItems(DecoratedComponent)));
