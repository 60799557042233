// @flow
import React, { Component } from 'react';
import type { ComponentType } from 'react';
import { connect } from 'react-redux';
import { withTranslatedRouter } from 'shared_data/providers/url/withTranslatedRouter';
import { bindActionCreators } from 'redux';
import get from 'lodash/get';
import { ErrorService } from 'shared_services/riseart/errors/ErrorService';
import { ExpiredToken, InvalidToken } from '@riseart/jwt';
import { store as STORE_CONFIG } from 'Config';
import { auth as AUTH_ENUM } from 'Enum';
import { localeSelector } from 'shared_services/redux/selectors/locale';
import { authRedirect, getAuthForwardDestination } from 'shared_services/riseart/utils/AuthUtils';
import { authTokenFetch } from 'shared_services/redux/actions/auth/auth';
import { errorAdd } from 'shared_services/redux/actions/errors/errors';

const { WEBSITE_OAUTH: WEBSITE_OAUTH_AUTH_MODULE, WEBSITE_LINK: WEBSITE_LINK_AUTH_MODULE } =
  AUTH_ENUM.modules;

const HOC_DISPLAY_NAME = 'HOCSocialLogin';

const FACEBOOK_PROVIDER = 'facebook';
const GOOGLE_PROVIDER = 'google';

type Props = Object;

/**
 * HOC
 *
 * @param {ComponentType<*>} SocialContainer
 */
function HOC(SocialContainer: ComponentType<*>) {
  return class extends Component<Props, void> {
    static displayName = HOC_DISPLAY_NAME;

    isProcessingSubmit: boolean = false;

    /**
     * constructor
     * @param props
     */
    constructor(props) {
      super(props);
      this.bindMethods();
    }

    /**
     * componentDidUpdate
     */
    componentDidUpdate() {
      const { history, auth, authParams, location, locale } = this.props;

      if (auth.status.error) {
        this.isProcessingSubmit = false;
        this.props.onLoadingChange(false);

        return;
      }

      if (this.isProcessingSubmit && auth.status.loading === false) {
        this.isProcessingSubmit = false;
        this.props.onLoadingChange(true);
        if (!this.props.skipRedirect) {
          authRedirect(authParams, history, locale, location);
        }

        if (typeof this.props.onComplete === 'function') {
          this.props.onComplete();
        }
      }
    }

    /**
     * render
     * @returns {XML}
     */
    render() {
      const { isLogin = true } = this.props;

      return (
        <SocialContainer
          heading={this.props.heading}
          align={this.props.align}
          setFacebookToken={this.handleFacebookLogin}
          setGoogleToken={this.handleGoogleLogin}
          isLogin={isLogin}
        />
      );
    }

    /**
     * showWarning
     * @param {string} detail
     */
    showWarning: Function;

    showWarning(detail: string = 'forms.auth_form.login_error') {
      this.props.actionErrorAdd(ErrorService.mapNotification({ detail }));
    }

    /**
     * handleFacebookLogin
     * @param token
     */
    handleFacebookLogin: Function;

    handleFacebookLogin(token) {
      this.handleLogin(token, FACEBOOK_PROVIDER);
    }

    /**
     * handleGoogleLogin
     * @param token
     */
    handleGoogleLogin: Function;

    handleGoogleLogin(token) {
      if (token && token.credential) {
        this.handleLogin(token.credential, GOOGLE_PROVIDER);
      }
    }

    /**
     * handleLogin
     * @param token
     */
    handleLogin(token, provider) {
      const AUTH_MODULE = this.props.linkAccount
        ? WEBSITE_LINK_AUTH_MODULE
        : WEBSITE_OAUTH_AUTH_MODULE;

      try {
        if (token) {
          this.props.onLoadingChange(true);
          const { authParams, locale } = this.props;

          this.props.actionTokenFetch({
            trigger: {
              data: {
                location: getAuthForwardDestination(authParams, locale),
              },
            },
            authModule: AUTH_MODULE,
            currentToken: this.props.token,
            authProviderToken: token,
            authProvider: provider,
            marketingCampaignId: authParams.marketingCampaignId,
            referrerId: authParams.referrerId,
            visitorId: this.props.visitorId,
            ...(authParams.quizResponseId ? { quizResponseId: authParams.quizResponseId } : null),
          });
          this.isProcessingSubmit = true;
        } else {
          this.showWarning();
          this.isProcessingSubmit = false;
        }
      } catch (Error) {
        if (Error instanceof ExpiredToken || Error instanceof InvalidToken) {
          this.props.actionTokenFetch({
            visitorId: this.props.visitorId,
            authModule: AUTH_MODULE,
          });
        } else {
          this.showWarning();
          this.isProcessingSubmit = false;
        }
        this.props.onLoadingChange(false);
      }
    }

    /**
     * bindMethods
     */
    bindMethods() {
      this.handleFacebookLogin = this.handleFacebookLogin.bind(this);
      this.handleGoogleLogin = this.handleGoogleLogin.bind(this);
      this.showWarning = this.showWarning.bind(this);
    }
  };
}

/**
 * MapStateToProps
 *
 * @param state
 * @returns {Object} redux store auth
 */
const mapStateToProps = (state) => ({
  auth: state[STORE_CONFIG.keys.auth],
  token: get(state[STORE_CONFIG.keys.auth], 'data.token'),
  visitorId: get(state[STORE_CONFIG.keys.me], 'data.visitor.id'),
  locale: localeSelector(state),
});

/**
 * mapDispatchToProps
 *
 * @param {Function} dispatch
 * @returns {Object} mapped actions
 */
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      actionTokenFetch: authTokenFetch,
      actionErrorAdd: errorAdd,
    },
    dispatch,
  );

/**
 * HOCSocialLogin
 *
 * @param {class} LoginForm
 * @returns {React.Element}
 */
export function HOCSocialLogin(SocialContainer: ComponentType<*>) {
  return connect<*, *, *, *, *, *>(
    mapStateToProps,
    mapDispatchToProps,
  )(withTranslatedRouter(HOC(SocialContainer)));
}
