// @flow

import React, { Component } from 'react';
import type { ComponentType } from 'react';
import { connect } from 'react-redux';
import { errors as ENUM_ERRORS } from 'Enum';
import { ErrorService, isInstanceOfJSError } from 'shared_services/riseart/errors/ErrorService';
import { errorAdd } from 'shared_services/redux/actions/errors/errors';

const HOC_DISPLAY_NAME = 'HOCCartErrors';

/**
 * HOCErrors
 *
 * @param {ComponentType<*>} DecoratedComponent
 */
const HOCErrors = (DecoratedComponent: ComponentType<*>) =>
  class extends Component<Object> {
    static displayName = HOC_DISPLAY_NAME;

    /**
     * constructor
     *
     * @param {Object} props
     */
    constructor(props: Object) {
      super(props);

      this.bindMethods();
    }

    /**
     * bindMethods
     */
    bindMethods() {
      this.handleLoading = this.handleLoading.bind(this);
    }

    /**
     * handleLoading
     *
     * @param {bolean} isLoading
     */
    handleLoading: Function;

    handleLoading(isLoading: boolean) {
      if (typeof this.props.onLoading === 'function') {
        this.props.onLoading(isLoading);
      }
    }

    /**
     * render
     */
    render() {
      return <DecoratedComponent {...this.props} />;
    }
  };

/**
 * mapDispatchToProps
 *
 * @param {Function} dispatch
 * @returns {Object} mapped actions
 */
const mapDispatchToProps = (dispatch: Function): { actionErrorAdd: Function } => {
  return {
    actionJSErrorAdd: (error) => {
      // Only disptach JS type of errors. Other errors are caught by other handlers
      if (isInstanceOfJSError(error)) {
        dispatch(errorAdd(ErrorService.mapJSError(error)));
      }
    },
    // Check for cart.messages in response, dispatch and show them in UI
    actionErrorAdd: (messages) => {
      if (!Array.isArray(messages)) {
        return;
      }

      const TYPE_TO_LEVEL = {
        error: ENUM_ERRORS.levels.ERROR,
        warning: ENUM_ERRORS.levels.WARNING,
        info: ENUM_ERRORS.levels.SUCCESS,
      };

      messages.forEach(({ type, message }) =>
        dispatch(
          errorAdd(
            ErrorService.mapNotification({
              level: TYPE_TO_LEVEL[type] || TYPE_TO_LEVEL.warning,
              detail: message,
              log: false,
            }),
          ),
        ),
      );
    },
  };
};

/**
 * HOCCartErrors
 *
 * @param {ComponentType<*>} DecoratedComponent
 */
export const HOCCartErrors = (DecoratedComponent: ComponentType<*>) =>
  connect<*, *, *, *, *, *>(null, mapDispatchToProps)(HOCErrors(DecoratedComponent));
