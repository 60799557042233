// @flow

import type { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { notFoundUpdate } from 'shared_services/redux/actions/application/notFound';
import { store as CONFIG_STORE } from 'Config';

function defaultMapDispatchToProps(dispatch) {
  return bindActionCreators({ actionNotFoundUpdate: notFoundUpdate }, dispatch);
}

/**
 * WithNotFoundState
 *
 * @param {Function} mapStateToProps
 * @param {Function} mapDispatchToProps
 * @returns {Function}
 */
export const WithNotFoundState =
  (mapStateToProps?: Function, mapDispatchToProps?: Function): Function =>
  (DecoratedComponent: Class<Component<*, *>>) =>
    connect(
      mapStateToProps
        ? (state: Object) => mapStateToProps(state[CONFIG_STORE.keys.notFound])
        : null,
      mapDispatchToProps || defaultMapDispatchToProps,
    )(DecoratedComponent);
