// @flow
import React, { Component } from 'react';
import type { ComponentType } from 'react';
import { connect } from 'react-redux';
import { withTranslatedRouter } from 'shared_data/providers/url/withTranslatedRouter';
import get from 'lodash/get';
import { graphql } from '@apollo/client/react/hoc';
import { bindActionCreators } from 'redux';
import { ExpiredToken, InvalidToken } from '@riseart/jwt';
import { store as CONFIG_STORE, application as CONFIG_APP } from 'Config';
import { auth as AUTH_ENUM } from 'Enum';
import { localeSelector } from 'shared_services/redux/selectors/locale';
import { ErrorService } from 'shared_services/riseart/errors/ErrorService';
import { authRedirect, getAuthForwardDestination } from 'shared_services/riseart/utils/AuthUtils';
import { authTokenFetch } from 'shared_services/redux/actions/auth/auth';
import { GTM_EVENT_TRIGGER_REASONS } from 'shared_services/redux/middleware/MiddlewareGoogleTagManager';
import { errorAdd } from 'shared_services/redux/actions/errors/errors';
import CREATE_USER_MUTATION from 'shared_data/queries/user/create.graphql';

const { WEBSITE_USER: WEBSITE_USER_AUTH_MODULE } = AUTH_ENUM.modules;
const HOC_DISPLAY_NAME = 'HOCRegisterForm';

type Props = Object;

/**
 * HOC
 *
 * @param {class} RegisterForm
 */
function HOC(RegisterForm) {
  return class extends Component<Props, *> {
    static displayName = HOC_DISPLAY_NAME;

    isProcessingSubmit: boolean = false;

    /**
     * constructor
     * @param props
     */
    constructor(props) {
      super(props);
      this.bindMethods();
    }

    /**
     * componentDidUpdate
     */
    componentDidUpdate() {
      const { history, auth, authParams, location, locale } = this.props;

      if (auth.status.error) {
        this.isProcessingSubmit = false;
        this.props.onLoadingChange(false);

        return;
      }

      if (this.isProcessingSubmit && auth.status.loading === false) {
        this.isProcessingSubmit = false;
        this.props.onLoadingChange(true);

        if (!this.props.skipRedirect) {
          authRedirect(authParams, history, locale, location);
        }
      }
    }

    /**
     * BindMethods
     */
    bindMethods() {
      this.submitHandler = this.submitHandler.bind(this);
      this.showWarning = this.showWarning.bind(this);
    }

    /**
     * showWarning
     * @param {string} detail
     */
    showWarning: Function;

    showWarning(detail: string = 'forms.auth_form.register_error') {
      this.props.actionErrorAdd(ErrorService.mapNotification({ detail }));
    }

    /**
     * SubmitHandler
     * @param data
     */
    submitHandler: Function;

    submitHandler(data) {
      const { firstName, lastName, email, password, subscribe } = data;
      const { visitorId, auth, authParams, locale } = this.props;
      this.props.onLoadingChange(true);

      if (data && firstName && lastName && email && password && subscribe !== null) {
        try {
          if (email && password && firstName && lastName && subscribe !== null) {
            const inputUser = {
              quizResponseId: authParams.quizResponseId,
              marketingCampaignId: authParams.marketingCampaignId,
              email,
              firstName,
              lastName,
              subscribe,
              referrerId: authParams.referrerId,
            };
            const inputAccount = {
              domain: CONFIG_APP.auth.register.domain,
              entityId: email,
              password,
            };
            const inputVisitor = {
              id: visitorId,
            };
            this.props
              .mutate({
                variables: {
                  inputUser,
                  inputAccount,
                  inputVisitor,
                },
              })
              .then((response) => {
                this.props.actionTokenFetch({
                  authModule: WEBSITE_USER_AUTH_MODULE,
                  trigger: {
                    reason: GTM_EVENT_TRIGGER_REASONS.registration,
                    data: {
                      location: getAuthForwardDestination(authParams, locale),
                    },
                  },
                  currentToken: auth.data.token,
                  username: response.data.createUser.email,
                  password,
                  ...(authParams.quizResponseId
                    ? { quizResponseId: authParams.quizResponseId }
                    : null),
                });
                this.isProcessingSubmit = true;
              })
              .catch(() => {
                this.props.onLoadingChange(false);
                this.isProcessingSubmit = false;
              });
          }
        } catch (Error) {
          if (Error instanceof ExpiredToken || Error instanceof InvalidToken) {
            this.props.actionTokenFetch({
              visitorId,
              authModule: WEBSITE_USER_AUTH_MODULE,
            });
          } else {
            this.showWarning();
            this.isProcessingSubmit = false;
          }
          this.props.onLoadingChange(false);
        }
      }
    }

    /**
     * render
     * @returns {XML}
     */
    render() {
      return (
        <RegisterForm
          submitListener={this.submitHandler}
          loginPath={this.props.authPath}
          showLogin={this.props.showLogin}
        />
      );
    }
  };
}

/**
 * MapStateToProps
 *
 * @param state
 * @returns {Object} redux store auth
 */
const mapStateToProps = (state) => ({
  auth: state[CONFIG_STORE.keys.auth],
  visitorId: get(state[CONFIG_STORE.keys.me], 'data.visitor.id'),
  locale: localeSelector(state),
});

/**
 * mapDispatchToProps
 *
 * @param {Function} dispatch
 * @returns {Object} mapped actions
 */
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ actionTokenFetch: authTokenFetch, actionErrorAdd: errorAdd }, dispatch);

/**
 * HOCFormRegister
 *
 * @param {class} HOCFormRegister
 * @returns {React.Element}
 */
export function HOCFormRegister(RegisterForm: ComponentType<*>) {
  const Register = graphql(CREATE_USER_MUTATION)(HOC(RegisterForm));
  return connect<*, *, *, *, *, *>(
    mapStateToProps,
    mapDispatchToProps,
  )(withTranslatedRouter(Register));
}
