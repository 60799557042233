// @flow
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';
import { withTranslatedRouter } from 'shared_data/providers/url/withTranslatedRouter';
import { authRedirect } from 'shared_services/riseart/utils/AuthUtils';
import queryString from 'query-string';
import get from 'lodash/get';
import { store as STORE_CONFIG } from 'Config';
import { localeSelector } from 'shared_services/redux/selectors/locale';
import { authTokenClear } from 'shared_services/redux/actions/auth/auth';
import UPDATE_VISITOR from 'shared_data/queries/visitor/update.graphql';

const HOC_DISPLAY_NAME = 'HOCSignout';

type Props = Object;

function HOCSignoutComponent(DecoratedComponent) {
  return class extends Component<Props, void> {
    static displayName = HOC_DISPLAY_NAME;

    isSigningOut = false;

    /**
     * constructor
     * @param props
     */
    constructor(props) {
      super(props);
      this.bindMethods();
    }

    /**
     * bindMethods
     */
    bindMethods() {
      this.handleClear = this.handleClear.bind(this);
    }

    /**
     * componentDidUpdate
     */
    componentDidUpdate() {
      this.doSignOut();
    }

    /**
     * componentDidMount
     */
    componentDidMount() {
      this.doSignOut();
    }

    /**
     * doSignOut
     */
    doSignOut() {
      const { autoLogin, visitorId } = this.props;

      if (this.isSigningOut || !visitorId) {
        return;
      }

      this.isSigningOut = true;

      if (autoLogin !== false) {
        const inputVisitor = {
          id: visitorId,
          autoLogin: false,
        };
        this.props
          .mutate({ variables: { inputVisitor } })
          .then(this.handleClear)
          .catch(this.handleClear);
      } else {
        this.handleClear();
      }
    }

    /**
     * handleClear
     */
    handleClear() {
      const { history, location, locale } = this.props;

      window.google.accounts.id.disableAutoSelect();

      this.props.actionTokenClear();
      const queryParameters = queryString.parse(location.state && location.state.forward);

      if (!this.props.skipRedirect) {
        authRedirect(queryParameters, history, locale);
      }
    }

    /**
     * render
     *
     * @returns {React$Element<*>}
     */
    render() {
      return <DecoratedComponent isActive isFullScreen />;
    }
  };
}

const mapStateToProps = (state) => {
  const me = state[STORE_CONFIG.keys.me];

  return {
    visitorId: get(me, 'data.visitor.id'),
    autoLogin: get(me, 'data.visitor.autoLogin'),
    locale: localeSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      actionTokenClear: authTokenClear,
    },
    dispatch,
  );
};

export function HOCSignout(DecoratedComponent: Class<React$Component<*, *, *>>): React$Element<*> {
  return connect<*, *, *, *, *, *>(
    mapStateToProps,
    mapDispatchToProps,
  )(withTranslatedRouter(graphql(UPDATE_VISITOR)(HOCSignoutComponent(DecoratedComponent))));
}
