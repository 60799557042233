// @flow

import React, { Component } from 'react';
import type { ComponentType } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { LocalizedConfig } from 'shared_services/riseart/utils/LocalizedConfig';
import { getGraphqlOperationName, cacheUpdateHandler } from 'shared_services/apollo/helpers';
import { meAddCredit } from 'shared_services/redux/actions/me/me';
import {
  useCredit,
  removeCredit,
  useCoupon,
  removeCoupon,
} from 'shared_services/redux/actions/cart/cart';
import {
  enterCheckoutStep,
  updateCheckoutStep,
  placeOrder,
} from 'shared_services/redux/actions/cart/checkout';
import { LocationManager } from 'shared_services/riseart/url/Location';
import { CartActionsOverlay } from 'shared_components/cart/actions/overlay/Overlay';
import READ_CART_QUERY from 'shared_data/queries/cart/read.graphql';
import UPDATE_CART_MUTATION from 'shared_data/queries/cart/update.graphql';

const HOC_DISPLAY_NAME = 'HOCCartSummary';

type State = {
  openedOverlay: ?string,
};

/**
 * HOCActions
 *
 * @param {ComponentType<*>} DecoratedComponent
 */
const HOCActions = (DecoratedComponent: ComponentType<*>) =>
  class extends Component<Object, State> {
    static displayName = HOC_DISPLAY_NAME;

    /**
     * constructor
     *
     * @param {*} props
     */
    constructor(props: Object) {
      super(props);

      this.bindMethods();
      const openedOverlay = this.getOverlayHash();

      this.state = { openedOverlay };
    }

    /**
     * getOverlayHash
     *
     * @returns {?string}
     */
    getOverlayHash() {
      const CONFIG_HASH = LocalizedConfig.get('navigation.uri.hash.params');
      const allowedOverlays = [CONFIG_HASH.credit, CONFIG_HASH.giftCard];
      const hash = LocationManager.get('hash');
      const overlayKey = hash && hash.substring(1);

      return overlayKey && allowedOverlays.indexOf(overlayKey) > -1 ? overlayKey : null;
    }

    /**
     * bindMethods
     */
    bindMethods() {
      this.handleOverlayOpen = this.handleOverlayOpen.bind(this);
      this.handleDiscountDelete = this.handleDiscountDelete.bind(this);
      this.handleDiscountApply = this.handleDiscountApply.bind(this);
      this.handleUseCredit = this.handleUseCredit.bind(this);
      this.handleCreditDelete = this.handleCreditDelete.bind(this);
      this.handleShippingChange = this.handleShippingChange.bind(this);
      this.handleGiftCardSubmit = this.handleGiftCardSubmit.bind(this);
      this.updateCart = this.updateCart.bind(this);
      this.createOrder = this.createOrder.bind(this);
      this.handleLoading = this.handleLoading.bind(this);
      this.handleError = this.handleError.bind(this);
    }

    /**
     * handleLoading
     *
     * @param {bolean} isLoading
     */
    handleLoading: Function;

    handleLoading(isLoading: boolean) {
      if (typeof this.props.onLoading === 'function') {
        this.props.onLoading(isLoading);
      }
    }

    /**
     * handleError
     */
    handleError: Function;

    handleError(error: Object) {
      this.props.actionJSErrorAdd(error);
      this.handleLoading(false);

      // Collect validation errors
      if (error && error.graphQLErrors) {
        error.graphQLErrors.forEach((gqlError) => {
          if (gqlError && gqlError.errorInfo && gqlError.errorInfo.validation) {
            const validationErrors = Object.keys(gqlError.errorInfo.validation).reduce(
              (accumulator, validationKey) => {
                const errorsList = gqlError.errorInfo.validation[validationKey];

                if (!errorsList) {
                  return accumulator;
                }

                const errors = Object.keys(errorsList).map((fieldKey) => ({
                  type: 'error',
                  message: errorsList[fieldKey],
                }));

                return [...accumulator, ...errors];
              },
              [],
            );

            if (validationErrors && validationErrors.length) {
              // Push errors using the standard cart action prop 'actionErrorAdd' provided by HOC
              this.props.actionErrorAdd(validationErrors);
            }
          }
        });
      }
    }

    /**
     * handleOverlayOpen
     *
     * @param {?string} visible overlay, null closes the overlay
     */
    handleOverlayOpen: Function;

    handleOverlayOpen(openedOverlay: ?string) {
      return () => this.setState({ openedOverlay });
    }

    /**
     * updateCart
     *
     * @param {Object} updatedData
     */
    updateCart: Function;

    updateCart(action: Function, updatedData: ?Object = {}, onSuccess?: Function) {
      if (!updatedData) {
        return;
      }

      this.handleLoading(true);
      this.handleOverlayOpen(null)();

      action({
        variables: {
          id: this.props.cartType,
          visitorId: this.props.visitorId,
          store: this.props.storeCode,
          ...updatedData,
        },
        update: cacheUpdateHandler(READ_CART_QUERY, getGraphqlOperationName(UPDATE_CART_MUTATION), {
          cartId: this.props.cartType,
          store: this.props.storeCode,
          visitorId: this.props.visitorId,
        }),
      })
        .then((response) => {
          this.responseHandler(response && response.data && response.data.updateCart, onSuccess);
        })
        .catch(this.handleError);
    }

    createOrder: Function;

    /**
     * createOrder
     *
     * @param {{ inputCheckout: Object, inputPayment: Object }} variables
     * @param {Function} onSuccess
     */
    createOrder(
      variables: { inputCheckout: Object, inputPayment: Object },
      onSuccess: Function,
      onError: Function,
    ) {
      this.handleLoading(true);

      return this.props
        .createOrderMutation({
          variables: {
            inputCheckout: {
              cartId: this.props.cartType,
              store: this.props.storeCode,
              visitorId: this.props.visitorId,
              ...variables.inputCheckout,
            },
            inputPayment: variables.inputPayment,
          },
        })
        .then((response) => {
          this.responseHandler(response && response.data && response.data.createOrder, onSuccess);
        })
        .catch((error) => {
          this.handleError(error);

          if (typeof onError === 'function') {
            onError(error);
          }
        });
    }

    /**
     * handleGiftCardSubmit
     *
     * @param {?Object} errors
     * @param {?Object} data
     */
    handleGiftCardSubmit: Function;

    handleGiftCardSubmit(data: ?Object) {
      if (!data || !data.voucherCode) {
        return;
      }

      this.handleLoading(true);
      this.handleOverlayOpen(null)();

      this.props
        .addCreditsMutation({
          variables: {
            store: this.props.storeCode,
            code: data.voucherCode,
            ...(data.securityCode ? { password: data.securityCode } : {}),
          },
        })
        .then((response) => {
          const { useCredit } = this.props;

          // Stop looader if useCredit is true. Otherwise the loader will be turned of after the credit is applied to the cart
          this.responseHandler(
            response && response.data && response.data.addMeCredits,
            null,
            useCredit,
          );
          this.props.actionMeAddCredit({
            id: this.props.id,
            cartType: this.props.cartType,
            subtotalAmount: this.props.subtotalAmount,
          });

          // Use credit, if not yet used, after gift card is redeemed
          if (!useCredit) {
            this.handleUseCredit({ target: { checked: true } });
          }
        })
        .catch(this.handleError);
    }

    /**
     * responseHandler
     *
     * @param {Object} responseData
     * @param {Function} callback
     * @param {boolean} stopLoader = true
     */
    responseHandler(responseData: Object, callback?: Function, stopLoader?: boolean = true) {
      if (responseData.messages && responseData.messages.length > 0) {
        this.props.actionErrorAdd(responseData.messages);
      }

      if (stopLoader) {
        this.handleLoading(false);
      }

      if (typeof callback === 'function') {
        callback(responseData);
      }
    }

    /**
     * handleDiscountApply
     *
     * @param {?Object} errors
     * @param {?Object} data
     */
    handleDiscountApply: Function;

    handleDiscountApply(data: ?Object) {
      if (!data) {
        return;
      }

      this.updateCart(
        this.props.updateCouponMutation,
        { couponCode: data.discountCode },
        this.props.actionUseCoupon,
      );
    }

    /**
     * handleDiscountDelete
     */
    handleDiscountDelete: Function;

    handleDiscountDelete() {
      this.updateCart(
        this.props.updateCouponMutation,
        { couponCode: null },
        this.props.actionRemoveCoupon,
      );
    }

    /**
     * handleUseCredit
     *
     * @param {Object} e
     */
    handleUseCredit: Function;

    handleUseCredit(e: Object) {
      const { checked } = e.target || {};

      this.updateCart(
        this.props.updateCartCreditMutation,
        { useCredit: !!checked },
        checked
          ? (data) => {
              this.props.actionUseCredit(data);
              this.props.actionErrorAdd([
                { type: 'info', message: 'components.cart.creditApplied' },
              ]);
            }
          : (data) => {
              this.props.actionRemoveCredit(data);
              this.props.actionErrorAdd([
                { type: 'info', message: 'components.cart.creditRemoved' },
              ]);
            },
      );
    }

    /**
     * handleCreditDelete
     */
    handleCreditDelete: Function;

    handleCreditDelete() {
      this.updateCart(this.props.updateCartCreditMutation, { useCredit: false }, (data) => {
        this.props.actionRemoveCredit(data);
        this.props.actionErrorAdd([{ type: 'info', message: 'components.cart.creditRemoved' }]);
      });
    }

    /**
     * handleShippingChange
     *
     * @param {string} countryCode
     */
    handleShippingChange: Function;

    handleShippingChange(countryCode: string) {
      this.updateCart(this.props.updateCartShippingMutation, {
        shippingAddress: { countryCode: countryCode.toLowerCase() },
      });
    }

    /**
     * mapCartDataToProps
     */
    mapCartDataToProps() {
      const CONFIG_HASH = LocalizedConfig.get('navigation.uri.hash.params');

      return {
        onShippingChange: this.handleShippingChange,
        onDiscountAdd: this.handleOverlayOpen(CONFIG_HASH.discount),
        onDiscountDelete: this.handleDiscountDelete,
        onCreditAdd: this.handleOverlayOpen(CONFIG_HASH.credit),
        onCreditDelete: this.handleCreditDelete,
        onGiftClick: this.handleOverlayOpen(CONFIG_HASH.credit),
        onGiftRedeemClick: this.handleOverlayOpen(CONFIG_HASH.giftCard),
        onCreateOrder: this.createOrder,
      };
    }

    /**
     * render
     */
    render() {
      const { openedOverlay } = this.state;
      const {
        children,
        meCustomerCredit,
        actionErrorAdd,
        actionJSErrorAdd,
        actionMeAddCredit,
        actionRemoveCoupon,
        actionRemoveCredit,
        actionUseCoupon,
        actionUseCredit,
        cartType,
        ...restProps
      } = this.props;

      return (
        <React.Fragment>
          {openedOverlay ? (
            <CartActionsOverlay
              cartType={cartType}
              openedOverlay={openedOverlay}
              storeCode={this.props.storeCode}
              customerCredit={this.props.meCustomerCredit}
              useCredit={this.props.useCredit}
              handleOverlayOpen={this.handleOverlayOpen}
              handleDiscountApply={this.handleDiscountApply}
              handleUseCredit={this.handleUseCredit}
              handleGiftCardSubmit={this.handleGiftCardSubmit}
            />
          ) : null}
          <DecoratedComponent
            cartType={cartType}
            {...restProps}
            {...this.mapCartDataToProps()}
            handleUseCredit={this.handleUseCredit}
            updateCart={this.updateCart}
            actionErrorAdd={actionErrorAdd}
          >
            {children}
          </DecoratedComponent>
        </React.Fragment>
      );
    }
  };

/**
 * mapStateToProps
 *
 * @param {Object} state
 * @return {Object}
 */
const mapStateToProps = (state: Object): Object => {
  const meState = state.STORE_me.data || {};

  return {
    meCustomerCredit: (meState.user && meState.user.customerCreditValue) || null,
  };
};

/**
 * mapDispatchToProps
 *
 * @param {Function} dispatch
 * @returns {Object}
 */
const mapDispatchToProps = (dispatch: Function): Object => {
  return bindActionCreators(
    {
      actionMeAddCredit: meAddCredit,
      actionUseCredit: useCredit,
      actionRemoveCredit: removeCredit,
      actionUseCoupon: useCoupon,
      actionRemoveCoupon: removeCoupon,
      actionEnterCheckoutStep: enterCheckoutStep,
      actionUpdateCheckoutStep: updateCheckoutStep,
      actionPlaceOrder: placeOrder,
    },
    dispatch,
  );
};

/**
 * HOCCartActions
 *
 * @param {ComponentType<*>} DecoratedComponent
 */
export const HOCCartActions = (DecoratedComponent: ComponentType<*>) =>
  connect<*, *, *, *, *, *>(mapStateToProps, mapDispatchToProps)(HOCActions(DecoratedComponent));
