// @flow

import React, { Component } from 'react';
import type { Node } from 'react';
import { connect } from 'react-redux';
import { Mutation } from '@apollo/client/react/components';
import { ApplicationMessage, ApplicationMessageList } from '@riseart/common';
import { Query } from 'shared_services/apollo/Query';
import { withTranslatedRouter } from 'shared_data/providers/url/withTranslatedRouter';
import { calcContainerHeight } from 'shared_hocs/messages/utils';
import { nameValue as ENUM_NAME_VAL } from 'Enum';
import {
  cookies as CONFIG_COOKIES,
  components as CONFIG_COMPONENTS,
  messages as CONFIG_MESSAGES,
} from 'Config';
import { selectVisitorId } from 'shared_services/redux/selectors/visitor';
import { selectStoreCode } from 'shared_services/redux/selectors/storeCode';
import { selectAclRole, selectAuthShippingCountryCode } from 'shared_services/redux/selectors/auth';
import { Status } from 'shared_components/routers/Status';
import { IconClose } from 'shared_components/common/button/IconClose';
import { Cookies } from 'shared_services/riseart/utils/Cookies/Cookies';
import { ApplicationMessagesRenderer } from 'shared_hocs/messages/application/Renderer';
import { SSRConsumer } from 'shared_data/providers/ssr/Consumer';
import { guiUpdate } from 'shared_services/redux/actions/application/gui';
import { matchCondition } from 'shared_services/riseart/utils/matchEngine';
import { getGraphqlOperationName, cacheUpdateHandler } from 'shared_services/apollo/helpers';
import CREATE_VISITOR_SETTING from 'shared_data/queries/visitorsetting/create.graphql';
import LIST_VISITOR_SETTING_QUERY from 'shared_data/queries/visitorsetting/list.graphql';
import { GUI_PROPERTIES } from 'shared_models/Gui';

type DefaultProps = {
  sort: 'asc' | 'desc',
};

type Props = {
  ...DefaultProps,
  htmlId: string,
  messageLocation: string,
  actionGuiUpdate: Function,
  cookieSettings: Array<Object>,
  location: Object,
  auth: Object,
  dismissedMessages: Array<{ id: string, type: string }>,
  cookieMessagesPersistent: Array<string>,
  cookieMessagesSession: Array<string>,
  createVisitorSetting: Function,
  loadingVisitorSettings: boolean,
};

type State = {
  loadingVisitorSettings: boolean,
  messages: Array<Object>,
  dismissedMessages: Array<{ id: string, type: string }>,
};

/**
 * getListPosition
 *
 * @param {Object} options
 * @param {string | null} device
 * @returns {number | null}
 */
function getListPosition(options: Object, device: string | null): number | null {
  if (options.listPosition) {
    if (
      typeof options.listPosition === 'object' &&
      (options.listPosition[device] || options.listPosition.desktop)
    ) {
      return options.listPosition[device] || options.listPosition.desktop;
    }

    return options.listPosition;
  }

  return null;
}

/**
 * filterApplicationMessages
 *
 * @param {Object} properties
 * @returns {Array<Object>}
 */
export function filterApplicationMessages({
  sort = 'asc',
  messageLocation,
  auth,
  location,
  dismissedMessages,
  loadingVisitorSettings,
  pagination,
  device,
}: Object): Array<Object> {
  const ascOrder = sort.toLowerCase() === 'asc';

  return CONFIG_MESSAGES.applicationMessages
    .filter(({ location, options }) => {
      const listPosition = getListPosition(options, device);

      return (
        !messageLocation ||
        (location === messageLocation &&
          (!pagination ||
            (pagination && (!pagination.offset || !pagination.limit)) ||
            (pagination &&
              listPosition !== null &&
              pagination.offset &&
              pagination.limit &&
              pagination.offset <= listPosition &&
              pagination.offset + pagination.limit > listPosition)))
      );
    })
    .map((message) => {
      const showMessage = message.conditions.every((condition) => {
        const conditionKey = Object.keys(condition)[0].trim();
        const value = condition[conditionKey];

        return matchCondition(conditionKey, value, ':', {
          options: message.options,
          location,
          auth,
        });
      });

      // Is message dismissed
      let isDismissed = false;

      if (
        message.options &&
        message.options.dismiss &&
        (message.options.type === CONFIG_COMPONENTS.messages.application.type.visitorSetting &&
        loadingVisitorSettings
          ? true
          : dismissedMessages &&
            dismissedMessages.some(
              (msg) =>
                msg.type === message.options.type &&
                msg.id ===
                  `${CONFIG_COMPONENTS.messages.application.service.prefix}${message.code}`,
            ))
      ) {
        isDismissed = true;
      }

      return {
        show: showMessage,
        dismissed: isDismissed,
        details: {
          ...message,
          options: { ...message.options, listPosition: getListPosition(message.options, device) },
        },
      };
    })
    .sort(({ details: { priority: aPriority } }, { details: { priority: bPriority } }) => {
      aPriority = aPriority || 0;
      bPriority = bPriority || 0;
      return ascOrder ? aPriority - bPriority : bPriority - aPriority;
    });
}

/**
 * ApplicationMessages
 */
export class ApplicationMessages extends Component<Props, State> {
  currentHeight: number = 0;

  dismissTimeoutId: TimeoutID;

  static defaultProps: DefaultProps = {
    sort: 'asc',
  };

  /**
   * constructor
   *
   * @params {Props} props
   */
  constructor(props: Props) {
    super(props);

    const { messageLocation, sort } = this.props;

    this.state = {
      dismissedMessages: [...props.dismissedMessages],
      messages: filterApplicationMessages({
        sort,
        messageLocation,
        auth: props.auth,
        location: props.location,
        dismissedMessages: props.dismissedMessages,
        loadingVisitorSettings: this.props.loadingVisitorSettings,
      }),
      loadingVisitorSettings: this.props.loadingVisitorSettings,
    };

    this.bindMethods();
  }

  /**
   * getDerivedStateFromProps
   *
   * @param {Props} props
   * @param {State} state
   * @returns {?State}
   */
  static getDerivedStateFromProps(props: Props, state: State): ?State {
    if (
      JSON.stringify(state.dismissedMessages) === JSON.stringify(props.dismissedMessages) &&
      state.loadingVisitorSettings === props.loadingVisitorSettings
    ) {
      return null;
    }

    return {
      loadingVisitorSettings: props.loadingVisitorSettings,
      dismissedMessages: [...props.dismissedMessages],
      messages: state.messages.map((message) => {
        return {
          ...message,
          dismissed:
            message.details.options &&
            message.details.options.type ===
              CONFIG_COMPONENTS.messages.application.type.visitorSetting &&
            props.loadingVisitorSettings
              ? true
              : props.dismissedMessages.some(
                  (msg) =>
                    msg.type === message.details.options.type &&
                    msg.id ===
                      `${CONFIG_COMPONENTS.messages.application.service.prefix}${message.details.code}`,
                ),
        };
      }),
    };
  }

  /**
   * selectActiveMessage
   *
   * @param {Array<Object>} messages
   * @returns {Object}
   */
  static selectActiveMessage(messages: Array<Object>): Object {
    let foundMessage = null;
    messages.some((message) => {
      if (message.show === true && message.dismissed !== true) {
        foundMessage = { ...message };
        return true;
      }

      return false;
    });

    return foundMessage;
  }

  /**
   * componentDidMount
   */
  componentDidMount() {
    this.calcAndUpdateContainerHeight();
  }

  /**
   * componentDidUpdate
   */
  componentDidUpdate() {
    this.calcAndUpdateContainerHeight();
  }

  /**
   * componentWillUnmount
   */
  componentWillUnmount() {
    clearTimeout(this.dismissTimeoutId);
    this.props.actionGuiUpdate(GUI_PROPERTIES.APPLICATION_MSG_HEIGHT, 0);
  }

  /**
   * calcAndUpdateContainerHeight
   */
  calcAndUpdateContainerHeight() {
    const newHeight = calcContainerHeight(this.props.htmlId, this.currentHeight, (height) =>
      this.props.actionGuiUpdate(GUI_PROPERTIES.APPLICATION_MSG_HEIGHT, height),
    );

    if (newHeight !== undefined && newHeight !== null) {
      this.currentHeight = newHeight;
    }
  }

  /**
   * handleDismiss
   *
   * @param {Object} msg
   * @returns
   */
  handleDismiss: Function;

  handleDismiss(msg: Object) {
    if (msg.options.timer) {
      clearTimeout(this.dismissTimeoutId);
      this.dismissTimeoutId = setTimeout(
        () => this.handleDismissAction(msg),
        msg.options.timer * 1000,
      );
      return;
    }

    return () => this.handleDismissAction(msg);
  }

  /**
   * handleDismissAction
   *
   * @param {Object} msg
   */
  handleDismissAction: Function;

  handleDismissAction(msg: Object) {
    const {
      service: { prefix: CONFIG_PREFIX },
      type: CONFIG_TYPE,
    } = CONFIG_COMPONENTS.messages.application;
    const msgName = `${CONFIG_PREFIX}${msg.code}`;
    this.setState((prevState) => ({
      messages: prevState.messages.map((message) => {
        if (message.details.code === msg.code) {
          return { ...message, show: false, dismissed: true };
        }

        return message;
      }),
      dismissedMessages: [...prevState.dismissedMessages, { id: msgName, type: msg.options.type }],
    }));

    if (!msg.options.dismiss) {
      return;
    }

    if (msg.options.type === CONFIG_TYPE.visitorSetting) {
      const { visitorId } = this.props.auth || {};

      const variables = {
        inputVisitorId: visitorId,
        inputVisitorSetting: {
          type: ENUM_NAME_VAL.type.BOOLEAN,
          name: msgName,
          value: true,
        },
      };

      if (visitorId) {
        this.props.createVisitorSetting({
          variables,
          update: cacheUpdateHandler(
            LIST_VISITOR_SETTING_QUERY,
            getGraphqlOperationName(CREATE_VISITOR_SETTING),
            variables,
            (cache, updatedData) => {
              const currentData = cache.readQuery({
                query: LIST_VISITOR_SETTING_QUERY,
                variables: { visitorId },
              });
              if (!currentData || !currentData.listVisitorSettings) {
                return;
              }

              // Add new follow record to query cache
              cache.writeQuery({
                query: LIST_VISITOR_SETTING_QUERY,
                variables: { visitorId },
                data: {
                  listVisitorSettings: [...currentData.listVisitorSettings, updatedData],
                },
              });
            },
          ),
        });
      }
    } else if (
      [CONFIG_TYPE.cookiePersistent, CONFIG_TYPE.cookieSession].indexOf(msg.options.type) > -1
    ) {
      const cookieConfig =
        CONFIG_COOKIES[
          msg.options.type === CONFIG_TYPE.cookiePersistent
            ? 'applicationMessagesPersistent'
            : 'applicationMessagesSession'
        ];

      Cookies.set(
        cookieConfig.name,
        JSON.stringify([...(JSON.parse(Cookies.get(cookieConfig.name) || null) || []), msgName]),
        cookieConfig,
      );
    }
  }

  /**
   * bindMethods
   */
  bindMethods() {
    this.handleDismiss = this.handleDismiss.bind(this);
    this.handleDismissAction = this.handleDismissAction.bind(this);
    this.calcAndUpdateContainerHeight = this.calcAndUpdateContainerHeight.bind(this);
  }

  /**
   * render
   *
   * @returns {Node}
   */
  render(): Node {
    const { htmlId, auth } = this.props;
    const { messages } = this.state;
    const activeMsg = ApplicationMessages.selectActiveMessage(messages);

    if (activeMsg) {
      if (activeMsg.details.options.dismiss) {
        this.handleDismiss(activeMsg.details);
      }
      return (
        <ApplicationMessageList id={htmlId}>
          <SSRConsumer>
            {({ isSSR }) => {
              if (!isSSR) {
                return null;
              }

              // Get the http status code from the active message
              // assuming the visitor has no settings
              // as this is ran server side and listVisitorSettings is client side only
              const { httpStatusCode } = activeMsg.details.options;
              return httpStatusCode ? <Status code={httpStatusCode} /> : null;
            }}
          </SSRConsumer>
          <ApplicationMessage
            {...activeMsg.details.options}
            messageId={activeMsg.details.code}
            {...(activeMsg.details.options.dismiss && !activeMsg.details.options.hideDismissIcon
              ? {
                  dismissibleComponent: (
                    <IconClose clickListener={this.handleDismiss(activeMsg.details)} />
                  ),
                }
              : this.handleDismiss(activeMsg.details))}
          >
            <ApplicationMessagesRenderer
              {...activeMsg.details.options}
              content={activeMsg.details.content}
              auth={auth}
              link={activeMsg.details.link}
              onHeightChange={this.calcAndUpdateContainerHeight}
              handleDismiss={this.handleDismiss(activeMsg.details)}
            />
          </ApplicationMessage>
        </ApplicationMessageList>
      );
    }

    return null;
  }
}

/**
 * HOCMessages
 *
 * @param {*} ApplicationMessageContainer
 * @returns
 */
function HOCMessages(ApplicationMessageContainer) {
  return class extends Component<Props> {
    cookieMessagesPersistent: Object = null;

    cookieMessagesSession: Object = null;

    constructor(props: Props) {
      super(props);

      this.cookieMessagesPersistent =
        JSON.parse(Cookies.get(CONFIG_COOKIES.applicationMessagesPersistent.name) || null) || [];
      this.cookieMessagesSession =
        JSON.parse(Cookies.get(CONFIG_COOKIES.applicationMessagesSession.name) || null) || [];
    }

    /**
     * render
     *
     * @returns {Node}
     */
    render() {
      const { htmlId, messageLocation, auth, actionGuiUpdate } = this.props;

      return auth && auth.visitorId ? (
        <Query
          query={LIST_VISITOR_SETTING_QUERY}
          variables={{ visitorId: auth.visitorId }}
          ssr={false}
        >
          {({ data, loading }) => {
            const visitorSettings = data && data.listVisitorSettings;
            // Combine dismissed messages from different resources: cookies, localStorage, visitorSettings
            let dismissedMessages = [
              ...this.cookieMessagesPersistent.map((msg) => ({
                id: msg,
                type: CONFIG_COMPONENTS.messages.application.type.cookiePersistent,
              })),
              ...this.cookieMessagesSession.map((msg) => ({
                id: msg,
                type: CONFIG_COMPONENTS.messages.application.type.cookieSession,
              })),
            ];

            if (visitorSettings) {
              dismissedMessages = visitorSettings.reduce(
                (aggregator, message) =>
                  message.value === 'true'
                    ? [
                        ...aggregator,
                        {
                          id: message.name,
                          type: CONFIG_COMPONENTS.messages.application.type.visitorSetting,
                        },
                      ]
                    : aggregator,
                dismissedMessages,
              );
            }

            return (
              <Mutation mutation={CREATE_VISITOR_SETTING}>
                {(createVisitorSetting) => (
                  <ApplicationMessageContainer
                    htmlId={htmlId}
                    loadingVisitorSettings={loading}
                    dismissedMessages={dismissedMessages}
                    createVisitorSetting={createVisitorSetting}
                    cookieMessagesPersistent={this.cookieMessagesPersistent}
                    cookieMessagesSession={this.cookieMessagesSession}
                    auth={auth}
                    actionGuiUpdate={actionGuiUpdate}
                    messageLocation={messageLocation}
                  />
                )}
              </Mutation>
            );
          }}
        </Query>
      ) : null;
    }
  };
}

export function HOCMessagesApplicationManager(ApplicationMessageContainer) {
  return connect<*, *, *, *, *, *>(
    (state) => ({
      auth: {
        visitorId: selectVisitorId(state),
        storeCode: selectStoreCode(state),
        shippingCountryCode: selectAuthShippingCountryCode(state),
        aclRole: selectAclRole(state),
      },
    }),
    (dispatch) => ({ actionGuiUpdate: (key, height) => dispatch(guiUpdate(key, height)) }),
  )(HOCMessages(withTranslatedRouter(ApplicationMessageContainer)));
}
