// @flow

import queryString from 'query-string';
import Url from 'url-parse';
import { Component } from 'react';
import type { Node } from 'react';
import { withTranslatedRouter } from 'shared_data/providers/url/withTranslatedRouter';
import { injectIntl } from 'react-intl';
import { collectPaginationMetaData } from 'shared_services/riseart/utils/pagination';
import { MetaService } from 'shared_services/riseart/meta/Meta';
import { UrlAssembler } from 'shared_services/riseart/utils/UrlAssembler';
import { meta as META_ENUM, pagination as PAGINATION_ENUM } from 'Enum';

type Props = {
  pageUrl: string,
  current: number,
  totalPages: number,
  isLoading: boolean,
  children: Node,
  onChange: Function,
  beforeChange?: Function,
  disableMeta?: ?boolean,
  paginationType: string,
  intl: Object,
  children: Function,
  history: Object,
  location: Object,
  regenerateMetaOnHydration: boolean,
};

class PaginationComponent extends Component<Props, *> {
  static defaultProps: Object = { beforeChange: null, disableMeta: null };

  static initialLoadFromHydration = false;

  /**
   * constructor
   *
   * @param {Props} props
   */
  constructor(props: Props) {
    super(props);

    this.bindMethods();

    if (this.isMetaDisabled()) {
      MetaService.resolveSubscription(META_ENUM.SUBSCRIBER_NAME.PAGINATION_META);
    }

    // must be called in constructor, so that is it called both on server-side and client
    this.addMetaData(props);
  }

  /**
   * componentDidUpdate
   *
   * @param {Props} prevProps
   */
  componentDidUpdate(prevProps: Props) {
    const { current, totalPages } = this.props;

    if (current !== prevProps.current || totalPages !== prevProps.totalPages) {
      this.addMetaData(this.props);
    }
  }

  /**
   * addMetaData
   *
   * @param {Props} props
   */
  addMetaData({ current = 1, totalPages = 1 }: Props) {
    if (this.isMetaDisabled()) {
      return;
    }

    const { content: canonicalLink } =
      MetaService.getMetaByType(META_ENUM.METATYPE.LINK_CANONICAL) || {};

    // regenerateMeta is only set to true the first time when PaginationComponent is rendered
    // after that the static property is set to true and it will never regenerateMeta after that
    let regenerateMeta = false;

    if (this.props.regenerateMetaOnHydration && !PaginationComponent.initialLoadFromHydration) {
      PaginationComponent.initialLoadFromHydration = true;
      regenerateMeta = true;
    }

    collectPaginationMetaData(
      canonicalLink,
      current,
      totalPages,
      regenerateMeta,
      this.props.intl.formatMessage,
    );
  }

  /**
   * isMetaDisabled
   *
   * @returns {boolean}
   */
  isMetaDisabled(): boolean {
    const { disableMeta, paginationType } = this.props;

    return (
      disableMeta === true ||
      (disableMeta === null && paginationType === PAGINATION_ENUM.type.INFINITE)
    );
  }

  /**
   * handlePageChange
   *
   * @param {number | boolean} page: number for slider pagination, boolean for infinite scroll
   * @returns {void}
   */
  handlePageChange: Function;

  handlePageChange(page: number | boolean): void {
    const {
      current,
      totalPages,
      isLoading,
      paginationType,
      beforeChange,
      pageUrl,
      history,
      location: { pathname },
    } = this.props;

    let goToPage;

    // if inisite scroll, the page parameter is a boolean, showing if next page should be loaded
    if (paginationType === PAGINATION_ENUM.type.INFINITE) {
      if (page && !isLoading) {
        goToPage = current + 1;
      } else {
        return;
      }
    } else {
      goToPage = page;
    }

    // $FlowFixMe
    if (goToPage > totalPages) {
      return;
    }

    // execute beforeChange prop if it exists
    if (typeof beforeChange === 'function') {
      beforeChange();
    }

    const parsedUrl = new Url(pageUrl);
    const urlOptions = UrlAssembler.mergeOptions({
      search: {
        ...(parsedUrl.query ? queryString.parse(parsedUrl.query) : null),
        ...(goToPage && goToPage > 1 ? { page: goToPage } : null),
      },
      ...(parsedUrl.hash ? { hash: parsedUrl.hash } : null),
    });

    // push new page url to the history
    history.push(`${pathname}${urlOptions.search}`);
  }

  /**
   * bindMethods
   */
  bindMethods() {
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  /**
   * render
   */
  render() {
    const { intl, pageUrl, children, onChange, ...restPaginationProps } = this.props;

    return children({
      ...restPaginationProps,
      translations: {
        page: intl.formatMessage({ id: 'components.pagination.page' }),
        next: intl.formatMessage({ id: 'components.pagination.next' }),
        previous: intl.formatMessage({ id: 'components.pagination.previous' }),
      },
      baseUrl: pageUrl,
      onChange: this.handlePageChange,
      urlAssembler: UrlAssembler,
    });
  }
}

export const Pagination = injectIntl(withTranslatedRouter(PaginationComponent));
