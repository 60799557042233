// @flow

import type { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { guiUpdate } from 'shared_services/redux/actions/application/gui';
import { store as CONFIG_STORE } from 'Config';

function defaultMapDispatchToProps(dispatch) {
  return bindActionCreators({ actionGuiUpdate: guiUpdate }, dispatch);
}

export const withGUIState =
  (mapStateToProps?: Function, mapDispatchToProps?: Function) =>
  (DecoratedComponent: Class<Component<*, *>>) =>
    connect(
      mapStateToProps ? (state: Object) => mapStateToProps(state[CONFIG_STORE.keys.gui]) : null,
      mapDispatchToProps || defaultMapDispatchToProps,
    )(DecoratedComponent);
