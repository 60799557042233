// @flow

import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { FilterService } from '@riseart/filter-service';
import { withTranslatedRouter } from 'shared_data/providers/url/withTranslatedRouter';
import { getFilterDomainConfig } from 'shared_models/configs/filters/domains';
import { validatedUrlParams } from 'shared_services/riseart/utils/FilterRouteUtils';

type Props = {
  includeMediumInStyle?: boolean,
  includePageRootLink?: boolean,
  filters: Object,
  filterDomain: string,
  config: Array<string>,
  children: Function,
  intl: Object,
  match: Object,
};

/**
 * breadcrumbLinkRenderer
 *
 * Decides the logic to render or not the dimension in breadcrumbs
 * and how to handle link name and value based on additional passed props and filter dimension config
 *
 * @param {string} filterKey
 * @param {Array<string>} filterData
 * @param {Object} options
 */
const breadcrumbLinkRenderer = (
  filterKey: string,
  filterData: Array<string>,
  { filters, filterService, includeMediumInStyle, formatMessage }: Object,
) => {
  if (!filterData || filterData.length > 1) {
    return;
  }

  // Check if dimension is child of a parent
  const parentDimension = filterService.getParentDimension(filterKey);

  // Do not add dimension to breadcrumbs if dimension has parent and its parent has multiple selected values
  if (parentDimension && filters[parentDimension] && filters[parentDimension].length > 1) {
    return;
  }

  // A specific case when name and url should include the medium in style link
  const includeMedium =
    filterKey === 'style' && includeMediumInStyle && filters && filters.medium && filters.medium[0];
  const dimension1 = filterService.getTranslatedKey(
    `${includeMedium ? 'breadcrumbs.' : ''}${filterKey}.${filterData[0]}`,
    filterKey,
    filterData[0],
  );
  const dimension2 = includeMedium
    ? ` ${filterService.getTranslatedKey(`medium.${filters.medium[0]}`)}`
    : null;

  const name = dimension2
    ? formatMessage(
        { id: `components.filters.breadcrumbs.${filterService.filterConfig.frontendDomain}` },
        {
          dimension1,
          dimension2,
          dimension1LowerCase: dimension1 ? dimension1.toLowerCase() : '',
          dimension2LowerCase: dimension2 ? dimension2.toLowerCase() : '',
        },
      )
    : dimension1;

  return {
    name,
    url: filterService.assemble(
      {
        ...(includeMedium ? { medium: filters.medium } : {}),
        ...(parentDimension && filters[parentDimension]
          ? { [parentDimension]: filters[parentDimension] }
          : {}),
        [filterKey]: filterData,
      },
      {},
    ),
  };
};

/**
 * FilterBreadcrumbsComponent
 */
class FilterBreadcrumbsComponent extends Component<Props> {
  static defaultProps = { includeMediumInStyle: false, includePageRootLink: true };

  filterService;

  /**
   * constructor
   *
   * @param {Props} props
   */
  constructor(props: Props) {
    super(props);

    const { filterDomain, intl, children } = this.props;
    const childrenType = typeof children;

    if (childrenType !== 'function') {
      throw new Error(
        `[FilterBreadcrumbs] Invalid children type ${childrenType} provided to FilterBreadcrumbs. This component accepts only function as children props.`,
      );
    }

    this.filterService = new FilterService(
      { ...getFilterDomainConfig(filterDomain, this.props.match.params) },
      intl.formatMessage,
    );
  }

  /**
   * render
   */
  render() {
    const {
      children,
      config,
      filterDomain,
      filters,
      includeMediumInStyle,
      includePageRootLink,
      intl,
    } = this.props;

    const validatedFilters = validatedUrlParams(filters, this.filterService.getConfig().dimensions);

    const breadcrumbsLinks =
      (config &&
        config.reduce((accumulator, filterKey) => {
          const filterData = validatedFilters[filterKey];
          const link = breadcrumbLinkRenderer(filterKey, filterData, {
            filters: validatedFilters,
            filterService: this.filterService,
            includeMediumInStyle,
            formatMessage: intl.formatMessage,
          });

          if (link) {
            accumulator.push(link);
          }

          return accumulator;
        }, [])) ||
      [];

    // Add a link to the page route
    if (includePageRootLink) {
      const { translationDomain, rootUrlPath } = getFilterDomainConfig(
        filterDomain,
        this.props.match.params,
      );
      breadcrumbsLinks.unshift({
        name: intl.formatMessage({
          id: `components.breadcrumbs.${translationDomain}`,
        }),
        url: rootUrlPath,
      });
    }

    return children(breadcrumbsLinks);
  }
}

export const FilterBreadcrumbs = injectIntl(withTranslatedRouter(FilterBreadcrumbsComponent));
