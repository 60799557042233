// @flow

import get from 'lodash/get';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import { FormBuilder } from '@riseart/form';
import { application as CONFIG_APP } from 'Config';
import { mailingList as ENUM_MAILING_LIST } from 'Enum';
import { withMeState } from 'shared_hocs/me/withMeState';
import { isSubscribed, isPromise } from 'shared_services/riseart/utils/Utils';
import { selectAuthShippingCountryCode } from 'shared_services/redux/selectors/auth';
import { selectStoreCode } from 'shared_services/redux/selectors/storeCode';

const HOC_DISPLAY_NAME = 'HOCEnquireForm';

type Props = {
  actionOpen: Function,
  actionSubmit: Function,
  trackedData: Object,
  initialValues: Object,
  additionalInitialValues?: Object,
  isAlreadySubscribed: boolean,
  children: Node,
  formSettings: Object,
  submitActionLabel: string,
  currencySign: string,
  intl: Object,
  createMailingList?: Function,
  storeCode: string,
  onSubmit: Function,
};

class HOCEnquire extends Component<Object, *> {
  static displayName = HOC_DISPLAY_NAME;

  /**
   * constructor
   * @param {Props} props
   */
  constructor(props: Props) {
    super(props);

    this.bindMethods();

    if (typeof this.props.actionOpen === 'function') {
      this.props.actionOpen(this.props.trackedData);
    }
  }

  /**
   * bindMethods
   */
  bindMethods() {
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAfterSubmit = this.handleAfterSubmit.bind(this);
  }

  /**
   * handleSubmit
   *
   * @param {string} error
   * @param {Object} values
   */
  handleSubmit: Function;

  handleSubmit(values: Object, formRef: Object) {
    const { onSubmit } = this.props;

    // limit message to 500 characters
    if (values && values.message && values.message.length > 500) {
      values.message = values.message.substring(0, 500);
    }

    // onSubmit may be a promise or a function
    if (isPromise(onSubmit)) {
      onSubmit(values, this.props, formRef).then(() => this.handleAfterSubmit(values));
    } else {
      onSubmit(values, this.props, formRef);
      this.handleAfterSubmit(values);
    }
  }

  /**
   * handleAfterSubmit
   *
   * @param {Object} values
   */
  handleAfterSubmit: Function;

  handleAfterSubmit(values) {
    const { isAlreadySubscribed, createMailingList, actionSubmit, storeCode } = this.props;

    if (typeof actionSubmit === 'function') {
      actionSubmit({ storeCode, ...values, ...this.props.trackedData });
    }

    if (isAlreadySubscribed && values.subscribe === undefined) {
      return;
    }

    if (values.subscribe && createMailingList) {
      createMailingList({
        variables: {
          inputMailingList: {
            type: ENUM_MAILING_LIST.type.TYPE_NEWSLETTER,
            emailAddress: values.email,
          },
        },
      });
    }
  }

  /**
   * render
   */
  render() {
    const {
      initialValues = {},
      additionalInitialValues = {},
      isAlreadySubscribed,
      children,
      formSettings,
      submitActionLabel,
      currencySign,
      intl,
      skipInitialValues,
      shippingCountryCode,
    } = this.props;

    return (
      <FormBuilder
        settings={formSettings.settings}
        fields={
          // Filter out subscribe fields if visitor is already subscribed
          isAlreadySubscribed
            ? formSettings.fields.filter(
                (field) =>
                  field.tag !== 'subscribeSection' && field.name !== 'subscribeDescription',
              )
            : formSettings.fields
        }
        {...(!skipInitialValues
          ? { initialValues: { ...initialValues, ...(additionalInitialValues || null) } }
          : null)}
        onSubmit={this.handleSubmit}
        submitActionLabel={submitActionLabel}
        customData={{ currencySign, countryCode: initialValues.countryCode || shippingCountryCode }}
        intl={intl}
      >
        {children}
      </FormBuilder>
    );
  }
}

/**
 * mapStateToProps
 *
 * @param {Object} meState
 * @returns {Object} mapped state
 */
const mapStateToProps = ({ data: meState = {} }: Object, state: Object): Object => {
  const { visitor, user } = meState || {};
  const storeCode = selectStoreCode(state);
  const countryCode = get(user, 'countryCode') || get(visitor, 'countryCode');
  const shippingCountryCode = selectAuthShippingCountryCode(state);
  const firstName = get(user, 'firstName');
  const lastName = get(user, 'lastName');

  return {
    initialValues: {
      firstName,
      lastName,
      fullName: [firstName, lastName].filter((item) => !!item).join(' '),
      countryCode,
      email: get(user, 'email') || get(visitor, 'email') || null,
      phone: get(user, 'phone'),
    },
    shippingCountryCode,
    storeCode,
    currencySign: CONFIG_APP.i18n.currency.signs[storeCode],
    isAlreadySubscribed: isSubscribed(get(visitor, 'emailSubscriptions', [])),
  };
};

/**
 * mapDispatchToProps
 *
 * @param {Object} mappedDispatchProps
 * @returns {Function} actual mapDispatchToProps
 */
const mapDispatchToProps =
  (mappedDispatchProps: Object = {}): Function =>
  (dispatch: Function): Object =>
    bindActionCreators(mappedDispatchProps, dispatch);

/**
 * HOCEnquireForm
 *
 * @param {ComponentType<*>} EnquireForm
 * @param {Object} mappedDispatchProps
 */
export function HOCEnquireForm(mappedDispatchProps: Object) {
  return withMeState(
    injectIntl(HOCEnquire),
    mapStateToProps,
    mapDispatchToProps(mappedDispatchProps),
  );
}
