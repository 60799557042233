// @flow

import { v4 } from 'uuid';
import isEqual from 'lodash/isEqual';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DataProviderPreloaderPage } from 'shared_components/data/providers/preloaders/Page';
import { cmsViewFetch } from 'shared_services/redux/actions/cms/cms';

type Props = {
  loading: boolean,
  data: Array<Object>,
  actionCmsViewFetch: Function,
};

type State = {
  data: ?Object,
};

/**
 * ViewsProvider
 */
class ViewsProvider extends Component<Props, State> {
  loaderUniqueId: string;

  /**
   * constructor
   * @param props
   */
  constructor(props) {
    super(props);
    this.loaderUniqueId = v4();

    this.state = { data: null };
  }

  /**
   * getDerivedStateFromProps
   * The state is used as a previous value of the data which is compared to the current props
   * This method is used specifically because it is run both on server and client
   *
   * @param {Props} props
   * @param {State} state
   */
  static getDerivedStateFromProps(props: Props, state: State) {
    if (props.data && !isEqual(props.data, state.data)) {
      const mappedData = props.data.reduce((accumulator, { placeholder, module, content }) => {
        accumulator[placeholder] = { module, content: JSON.parse(content) };
        return accumulator;
      }, {});

      if (mappedData) {
        props.actionCmsViewFetch(mappedData);

        return {
          data: props.data,
        };
      }
    }

    return null;
  }

  /**
   * render
   */
  render() {
    return <DataProviderPreloaderPage uniqueId={this.loaderUniqueId} attach={this.props.loading} />;
  }
}

/**
 * mapDispatchToProps
 *
 * @param {Function} dispatch
 * @returns {Object} mapped actions
 */
const mapDispatchToProps = (dispatch) => ({
  actionCmsViewFetch: (payload) => dispatch(cmsViewFetch(payload)),
});

/**
 * CmsViewsProvider
 */
export const CmsViewsProvider = connect(null, mapDispatchToProps)(ViewsProvider);
