// @flow

import React, { Component } from 'react';
import type { ComponentType } from 'react';
import { injectIntl } from 'react-intl';
import { formatCurrency } from 'shared_services/riseart/utils/Utils';
import { localeSelector } from 'shared_services/redux/selectors/locale';
import { WithCheckoutUrl } from 'shared_hocs/cart/WithCheckoutUrl';
import { WithStore } from 'shared_hocs/connect/withStore';
import { shippingBuy as BUY_COUNTRIES, shippingRent as RENT_COUNTRIES } from 'Countries';

const HOC_DISPLAY_NAME = 'HOCCartSummary';

type State = {
  openedOverlay: ?string,
};

/**
 * HOCSummary
 *
 * @param {ComponentType<*>} DecoratedComponent
 */
const HOCSummary = (DecoratedComponent: ComponentType<*>) =>
  class extends Component<Object, State> {
    static displayName = HOC_DISPLAY_NAME;

    /**
     * constructor
     *
     * @param {*} props
     */
    constructor(props: Object) {
      super(props);

      this.bindMethods();
    }

    /**
     * bindMethods
     */
    bindMethods() {
      this.currencyFormatter = this.currencyFormatter.bind(this);
    }

    /**
     * currencyFormatter
     *
     * @param {number | string} price
     */
    currencyFormatter: Function;

    currencyFormatter(localeCode: string) {
      return (price: number | string) => {
        return formatCurrency(price, this.props.storeCode, undefined, {
          locale: localeCode,
        });
      };
    }

    /**
     * mapCartDataToProps
     *
     * @param {Object} props
     */
    mapCartDataToProps(props: Object) {
      const {
        intl,
        subtotalAmount,
        totalAmount,
        shippingAddress,
        shippingAmount,
        discountAmount,
        couponCode,
        useCredit,
        creditsAmount,
        rentedItem,
        rental,

        onShippingChange,
        onDiscountAdd,
        onDiscountDelete,
        onCreditAdd,
        onCreditDelete,
        onGiftClick,
        onGiftRedeemClick,
        canEditShipping,
      } = props;

      return {
        subtotalAmount,
        shippingAmount,
        discountAmount,
        creditsAmount,
        totalAmount,
        shippingAddress,
        couponCode,
        useCredit,
        rental,
        rentalPeriod:
          rental && rentedItem && rentedItem.rentalPeriod
            ? intl.formatMessage({ id: `common.durations.${rentedItem.rentalPeriod}.full` })
            : null,
        // Generated props from external resources (utils, enums, react-intl, etc.)
        formatMessage: intl.formatMessage,
        countryCodeList: props.rental ? RENT_COUNTRIES : BUY_COUNTRIES,

        // Control props
        isShippingFree: shippingAmount === 0,
        hasShipping: shippingAmount !== null,
        hasDiscount: discountAmount !== null && discountAmount !== 0,
        isCreditApplied: useCredit && creditsAmount !== null,
        canEditShipping,

        // Handler props
        onShippingChange,
        onDiscountAdd,
        onDiscountDelete,
        onCreditAdd,
        onCreditDelete,
        onGiftClick,
        onGiftRedeemClick,

        // Translation props
        translations: {
          checkoutBtnLabel: intl.formatMessage({ id: 'components.cart.continueToCheckout' }),
          discount: intl.formatMessage({ id: 'components.cart.discountCode' }),
          credit: intl.formatMessage({ id: 'components.cart.credit' }),
          giftCard: intl.formatMessage({ id: 'components.cart.giftCard' }),
          subtotal: intl.formatMessage({ id: 'components.cart.subtotal' }),
          shipping: intl.formatMessage({ id: 'components.cart.shipping' }),
          total: intl.formatMessage({ id: 'components.cart.total' }),
          freeShipping: intl.formatMessage({ id: 'components.cart.freeShipping' }),
          to: intl.formatMessage({ id: 'common.to' }),
          apply: intl.formatMessage({ id: 'common.apply' }),
          remove: intl.formatMessage({ id: 'common.remove' }),
          rentalCreditNote: intl.formatMessage({ id: 'components.cart.rentalCreditNote' }),
          usingCouponCode: couponCode
            ? intl.formatMessage({ id: 'components.cart.usingCouponCode' }, { couponCode })
            : null,
        },
      };
    }

    /**
     * render
     */
    render() {
      return (
        <WithCheckoutUrl isRental={this.props.rental}>
          {(checkoutUrl) => (
            <WithStore
              mapStateToProps={(state: Object): Object => ({
                locale: localeSelector(state),
              })}
            >
              {({ locale }) => (
                <DecoratedComponent
                  {...this.mapCartDataToProps(this.props)}
                  currencyFormatter={this.currencyFormatter(locale && locale.name)}
                  checkoutUrl={checkoutUrl}
                  config={this.props.config}
                >
                  {this.props.children}
                </DecoratedComponent>
              )}
            </WithStore>
          )}
        </WithCheckoutUrl>
      );
    }
  };

/**
 * HOCCartSummary
 *
 * @param {ComponentType<*>} DecoratedComponent
 */
export const HOCCartSummary = (DecoratedComponent: ComponentType<*>) =>
  injectIntl(HOCSummary(DecoratedComponent));
