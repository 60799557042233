// @flow

import React from 'react';
import type { Node } from 'react';
import { useIntl } from 'react-intl';
import { Link } from '@riseart/common';
import { RiseartLogger } from 'shared_services/riseart/Logger';
import { IconBannerMessage } from 'shared_components/messages/IconBanner';
import { Kazoart } from 'shared_components/messages/Kazoart';
import { ArtistSpotlightMessage } from 'shared_components/messages/ArtistSpotlight';
import { ConsentMessage } from 'shared_components/messages/Consent';
import { BlackFridayMessage } from 'shared_components/messages/BlackFriday';
import { ChristmasDelivery } from 'shared_components/messages/christmas/Delivery';

const CONTENT_TYPE = {
  HTML: 'html',
  TEXT: 'text',
  COMPONENT: 'component',
};

const COMPONENTS = {
  IconBanner: (componentProps: Object = {}) => <IconBannerMessage {...componentProps} />,
  Kazoart: (componentProps: Object = {}) => <Kazoart {...componentProps} />,
  ChristmasDelivery: (componentProps: Object = {}) => <ChristmasDelivery {...componentProps} />,
  BlackFridayMessage: (componentProps: Object = {}) => <BlackFridayMessage {...componentProps} />,
  ArtistSpotlightMessage: (componentProps: Object = {}, auth: Object = {}) => (
    <ArtistSpotlightMessage {...componentProps} storeCode={auth && auth.storeCode} />
  ),
  ConsentMessage: (componentProps: Object = {}, auth: Object = {}) => (
    <ConsentMessage {...componentProps} shippingCountryCode={auth.shippingCountryCode} />
  ),
};

/**
 * ApplicationMessagesRenderer
 *
 * @param {Object} messageData
 * @param {Object} helpers
 */
export const ApplicationMessagesRenderer = ({
  contentType,
  content,
  componentName,
  componentProps,
  link,
  auth,
  onHeightChange,
  handleDismiss,
}: Object = {}): Node => {
  const intl = useIntl();
  let msgComponent = null;

  switch (contentType) {
    case CONTENT_TYPE.COMPONENT:
      if (componentName) {
        const componentHandler = COMPONENTS[componentName];

        if (!componentHandler || typeof componentHandler !== 'function') {
          RiseartLogger.exception(new Error('Not implemented component for application message'), {
            scope: 'ApplicationMessages.applicationMessages',
            componentName,
          });
          return null;
        }

        msgComponent = componentHandler({ ...componentProps, handleDismiss, onHeightChange }, auth);
      }
      break;
    case CONTENT_TYPE.TEXT:
    case CONTENT_TYPE.HTML:
      msgComponent = (
        <div dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: content }) }} />
      );
      break;
    default:
      return null;
  }

  if (msgComponent && link) {
    return (
      <Link type="light" external href={link} title="">
        {msgComponent}
      </Link>
    );
  }

  return msgComponent;
};
