// @flow

import { v4 } from 'uuid';
import queryString from 'query-string';
import get from 'lodash/get';
import React, { Component } from 'react';
import { withTranslatedRouter } from 'shared_data/providers/url/withTranslatedRouter';
import { wrapperPaddings } from '@riseart/layout';
import {
  SliderPagination,
  InfiniteScrollPagination,
  ScrollToTopButton,
  scrollToElement,
} from '@riseart/common';
import { GridPaginationWrapper } from '@riseart/grid';
import { pagination as PAGINATION_ENUM } from 'Enum';
import { Query } from 'shared_services/apollo/Query';
import { DataProviderPreloaderPage } from 'shared_components/data/providers/preloaders/Page';
import { IsomorphicRipple } from 'shared_components/common/preloader/IsomorphicRipple';
import { Pagination } from 'shared_hocs/pagination/Pagination';

const ITEMS_PER_PAGE = 10;
const HOC_DISPLAY_NAME = 'HOCGridPaginated';

/**
 * HOCGrid
 * @param {*} DecoratedComponent
 */
function HOCGrid(DecoratedComponent: any) {
  return class extends Component<*, *> {
    static displayName = HOC_DISPLAY_NAME;

    gridRef: Object;

    preloaderUniqueId: string;

    itemsList: Array<?Object> = [];

    paginationState: Object;

    scrollToTopOfGrid: Function;

    static defaultProps = {
      itemsPerPage: ITEMS_PER_PAGE,
      ssrQuery: false,
      isSSR: false,
    };

    /**
     * Constructor
     *
     * @param props
     */
    constructor(props) {
      super(props);

      this.gridRef = React.createRef();
      this.bindMethods();

      this.paginationState = this.initialPaginationState();
      this.preloaderUniqueId = v4();
    }

    /**
     * initialPaginationState
     */
    initialPaginationState() {
      return {
        current:
          this.props.paginationType === PAGINATION_ENUM.type.SLIDER ? this.getCurrentQsPage() : 1,
        totalPages: 0,
        total: 0,
        pageSize: this.props.itemsPerPage,
        isLoading: false,
      };
    }

    /**
     * beforeInfinitePageChange
     *
     * @returns {void}
     */
    beforeInfinitePageChange: Function;

    beforeInfinitePageChange(): void {
      this.paginationState.isLoading = true;
    }

    /**
     * getCurrentQsPage
     *
     * @returns {number} page from query string
     */
    getCurrentQsPage(): number {
      return parseInt(queryString.parse(this.props.location.search).page || 1, 10);
    }

    /**
     * bindMethods
     */
    bindMethods() {
      this.beforeInfinitePageChange = this.beforeInfinitePageChange.bind(this);
      this.scrollToTopOfGrid = scrollToElement.bind(this, this.gridRef);
    }

    /**
     * updateItemsList
     *
     * @param {Object} data
     * @returns {void}
     */
    updateItemsList: Function;

    updateItemsList(responseData: Object = null): void {
      if (!responseData) {
        this.paginationState.isLoading = false;
        return;
      }

      const { pagination, items } = responseData;

      // Append to list of items for infinte scroll, otherwise create new list with items for slider pagination
      if (
        this.props.paginationType === PAGINATION_ENUM.type.INFINITE &&
        this.paginationState.isLoading
      ) {
        this.itemsList = [...this.itemsList, ...items];
      } else {
        this.itemsList = [...items];
      }

      // Update paginationState with pagination data from query after loading new items in this.itemsList
      this.paginationState = {
        ...this.paginationState,
        current: pagination.currentPage,
        totalPages: pagination.totalPages,
        total: pagination.totalItems,
        pageSize: pagination.itemsPerPage,
        isLoading: false,
      };
    }

    /**
     * render
     */
    render() {
      const {
        fetchQuery,
        itemsPerPage,
        variables,
        paginationType,
        ssrQuery,
        skipQuery = false,
        isSSR,
        autoLoading = true,
      } = this.props;
      const queryDataName = get(fetchQuery, 'definitions[0].name.value');

      return (
        <Query
          query={fetchQuery}
          variables={{
            ...variables,
            page:
              this.paginationState.total === 0
                ? this.paginationState.current
                : this.getCurrentQsPage(),
            items: itemsPerPage,
          }}
          ssr={ssrQuery}
          skip={skipQuery}
        >
          {({ loading, data, error }) => {
            const responseData = data ? data[queryDataName] : null;

            // Update the items that will be shown before rendering the grid
            if (data && !loading && !error) {
              this.updateItemsList(responseData);
            }

            const totalItems = (this.itemsList && this.itemsList.length) || 0;

            return (
              <React.Fragment>
                <DataProviderPreloaderPage uniqueId={this.preloaderUniqueId} attach={loading} />
                <span ref={this.gridRef} />
                <DecoratedComponent
                  wrapperPaddings={wrapperPaddings}
                  items={this.itemsList}
                  pagination={responseData && responseData.pagination}
                  isLoading={!this.paginationState.isLoading && loading}
                />
                {(paginationType === PAGINATION_ENUM.type.SLIDER &&
                  isSSR &&
                  this.paginationState.totalPages > 0) ||
                totalItems ? (
                  <GridPaginationWrapper>
                    <Pagination
                      paginationType={paginationType}
                      current={this.paginationState.current}
                      total={this.paginationState.total}
                      totalPages={this.paginationState.totalPages}
                      pageSize={this.paginationState.pageSize}
                      pageUrl={this.props.pageUrl}
                      beforeChange={
                        (paginationType === PAGINATION_ENUM.type.INFINITE &&
                          this.beforeInfinitePageChange) ||
                        this.scrollToTopOfGrid
                      }
                      isLoading={this.paginationState.isLoading}
                      offset={{ top: 0, left: 0, bottom: -1000, right: 0 }}
                      regenerateMetaOnHydration={!isSSR && !!responseData}
                    >
                      {(props) => {
                        return paginationType === PAGINATION_ENUM.type.SLIDER ? (
                          <SliderPagination {...props} />
                        ) : (
                          <InfiniteScrollPagination
                            {...props}
                            loader={IsomorphicRipple}
                            autoLoading={autoLoading}
                          />
                        );
                      }}
                    </Pagination>
                  </GridPaginationWrapper>
                ) : null}
                {totalItems && paginationType === PAGINATION_ENUM.type.INFINITE ? (
                  <ScrollToTopButton />
                ) : null}
              </React.Fragment>
            );
          }}
        </Query>
      );
    }
  };
}

/**
 * HOCGridPaginated
 *
 * @param {any} DecoratedComponent
 */
export const HOCGridPaginated = (DecoratedComponent: any) => {
  return withTranslatedRouter(HOCGrid(DecoratedComponent));
};
